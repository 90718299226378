import React, { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import { ApiBaseUrl } from "../../utils/urlbase";
import axios from "axios";
import Helmet from "react-helmet";
import {
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
  FormControlLabel,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";

import { dateTimeFormat } from "../../utils/utils";
import { Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Audit = () => {
  const token = localStorage.getItem("token");

  const [logs, setLogs] = useState([]);
  const [logsCollection, setLogsCollection] = useState([]);
  const [logsPage, setLogsPage] = useState(0);
  const [rowsPerLogsPage, setRowsPerLogsPage] = useState(7);

  const updateLogsPage = (p) => {
    setLogsPage(p);
    const to = rowsPerLogsPage * (p + 1);
    const from = to - rowsPerLogsPage;
    setLogsCollection(logs);
  };

  const handleChangeLogsPage = (event, newPage) => {
    setLogsPage(newPage);
  };

  const handleChangeRowsPerLogsPage = (event) => {
    setRowsPerLogsPage(event.target.value);
    setLogsPage(0);
  };

  const fetchLogs = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.AuditTrail.LoginHistory} `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLogs(res.data.data);
        setLogsCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLogs();
    fetchTranLogs();
  }, []);

  const [transactions, setTransactions] = useState([]);

  const fetchTranLogs = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.AuditTrail.TranHistory} `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTransactions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Navigation>
      <Helmet>
        <title>Audit Trail | BMS</title>
      </Helmet>
      <div style={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Transaction Trail" {...a11yProps(0)} />
            <Tab label="Login Trail" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {/** Transaction Trail */}
        <TabPanel value={value} index={0}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h4"
          >
            Transaction Trail
          </Typography>

          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ref Code</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Initiator</TableCell>
                  <TableCell>Affected User</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.length > 0 ? (
                  transactions
                    /** .slice(
                      logsPage * rowsPerLogsPage,
                      logsPage * rowsPerLogsPage + rowsPerLogsPage
                    )*/
                    .map((tran, index) => (
                      <TableRow key={index}>
                        <TableCell>{tran.TranRefCode}</TableCell>
                        <TableCell>{tran.TranDetails.Description}</TableCell>
                        <TableCell>
                          {tran.TranDetails.StatusIndicator}
                        </TableCell>
                        <TableCell>
                          {tran.TranDetails.Initiator
                            ? tran.TranDetails.Initiator.UserName
                            : ""}
                        </TableCell>
                        <TableCell>
                          {tran.TranDetails.AffectedUser
                            ? tran.TranDetails.AffectedUser.UserName
                            : ""}
                        </TableCell>
                        <TableCell>{dateTimeFormat(tran.CreatedAt)}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No logs yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            {/** <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={logs.length}
                rowsPerPage={rowsPerLogsPage}
                page={logsPage}
                onPageChange={handleChangeLogsPage}
                onRowsPerPageChange={handleChangeRowsPerLogsPage}
              />
            </TableFooter> */}
          </Card>
        </TabPanel>
        {/** Login Trail */}
        <TabPanel value={value} index={1}>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h4"
          >
            Login Trail
          </Typography>

          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Browser</TableCell>
                  <TableCell>Platform</TableCell>
                  <TableCell>Date Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.length > 0 ? (
                  logsCollection
                    /** .slice(
                      logsPage * rowsPerLogsPage,
                      logsPage * rowsPerLogsPage + rowsPerLogsPage
                    )*/
                    .map((log, index) => (
                      <TableRow key={index}>
                        <TableCell>{log.Description}</TableCell>
                        <TableCell>{log.Client.IP}</TableCell>
                        <TableCell>{log.Client.Device.Browser}</TableCell>
                        <TableCell>{log.Client.Device.Platform}</TableCell>
                        <TableCell>{dateTimeFormat(log.Timestamp)}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No logs yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            {/** <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={logs.length}
                rowsPerPage={rowsPerLogsPage}
                page={logsPage}
                onPageChange={handleChangeLogsPage}
                onRowsPerPageChange={handleChangeRowsPerLogsPage}
              />
            </TableFooter> */}
          </Card>
        </TabPanel>
      </div>
    </Navigation>
  );
};
