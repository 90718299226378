import * as React from "react";
import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Button } from "@mui/material";
import logo from "../../media/images/oddictslogowhite.svg";
import SportsBaseballRoundedIcon from "@mui/icons-material/SportsBaseballRounded";
import { useNavigate } from "react-router-dom";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { GlobalContext } from "../../context/GlobalState";
import { HFCode, AccessControl } from "../../utils/hfcode";
import { BiMoney } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { AiFillCalculator } from "react-icons/ai";
import { FaExchangeAlt } from "react-icons/fa";
import { RiSpyFill } from "react-icons/ri";
import { GiBasketballJersey } from "react-icons/gi";
import { HiDocumentText } from "react-icons/hi";
import { IoMdWallet } from "react-icons/io";
import { BsBookmarkFill } from "react-icons/bs";
const drawerWidth = 240;

function Navigation(props) {
  const token = localStorage.getItem("token");
  const { getPermissions, contextData, contextLoading } =
    useContext(GlobalContext);

  useEffect(() => {
    getPermissions(token);
    //console.log(contextData.IsAgent);
  }, [contextLoading]);

  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    return navigate("/login");
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          color: "#757575",
          padding: "1.5rem 0 0 1.3rem",
        }}
      >
        MANAGEMENT
      </Typography>
      <List>
        {contextLoading ? null : (
          <>
            {contextData.Permissions.HFCode.length > 0 ? (
              <>
                <ListItem
                  button
                  component="button"
                  onClick={() => navigate("/")}
                >
                  <ListItemIcon>
                    <HomeRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  button
                  component="button"
                  onClick={() => navigate(`/myledger/${contextData.SeqID}`)}
                >
                  <ListItemIcon>
                    <BsBookmarkFill fontSize={21} />
                  </ListItemIcon>
                  <ListItemText primary="My Ledger" />
                </ListItem>
                <ListItem
                  button
                  component="button"
                  onClick={() => navigate("/fundtransfer")}
                >
                  <ListItemIcon>
                    <FaExchangeAlt fontSize={26} />
                  </ListItemIcon>
                  <ListItemText primary="Fund Transfer" />
                </ListItem>
                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.GamesRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate("/games")}
                  >
                    <ListItemIcon>
                      <SportsBaseballRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Games" />
                  </ListItem>
                ) : null}
                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.GamesRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate("/personalities")}
                  >
                    <ListItemIcon>
                      <GiBasketballJersey fontSize={27} />
                    </ListItemIcon>
                    <ListItemText primary="Teams & Players" />
                  </ListItem>
                ) : null}
                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.AuditRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate("/audit")}
                  >
                    <ListItemIcon>
                      <HiDocumentText fontSize={27} />
                    </ListItemIcon>
                    <ListItemText primary="Audit Trail" />
                  </ListItem>
                ) : null}
                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.UsersRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate("/users")}
                  >
                    <ListItemIcon>
                      <AccountCircleRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="User accounts" />
                  </ListItem>
                ) : null}

                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.AgentRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate("/agent")}
                  >
                    <ListItemIcon>
                      <RiSpyFill fontSize={26} />
                    </ListItemIcon>
                    <ListItemText primary="My Agent Account" />
                  </ListItem>
                ) : null}

                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.BetSlipsRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() =>
                      navigate("/betslips", {
                        state: {
                          slipsid: false,
                        },
                      })
                    }
                  >
                    <ListItemIcon>
                      <ConfirmationNumberIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bet Slips" />
                  </ListItem>
                ) : null}
                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.CommissionsRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() =>
                      navigate(`/commissions/${contextData.AgentID}`)
                    }
                  >
                    <ListItemIcon>
                      <BiMoney fontSize={26} />
                    </ListItemIcon>
                    <ListItemText primary="My Commissions" />
                  </ListItem>
                ) : null}

                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.RolesRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate(`/roles`)}
                  >
                    <ListItemIcon>
                      <HiUsers fontSize={26} />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                  </ListItem>
                ) : null}

                {contextData.Permissions.HFCode.some(
                  (code) => AccessControl.AccountingRoute.indexOf(code) >= 0
                ) ? (
                  <ListItem
                    button
                    component="button"
                    onClick={() => navigate(`/accounting/user`)}
                  >
                    <ListItemIcon>
                      <AiFillCalculator fontSize={26} />
                    </ListItemIcon>
                    <ListItemText primary="Accounting" />
                  </ListItem>
                ) : null}
              </>
            ) : (
              <>
                <ListItem
                  button
                  component="button"
                  onClick={() => navigate("/")}
                >
                  <ListItemIcon>
                    <HomeRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  button
                  component="button"
                  onClick={() => navigate("/fundtransfer")}
                >
                  <ListItemIcon>
                    <FaExchangeAlt fontSize={26} />
                  </ListItemIcon>
                  <ListItemText primary="Fund Transfer" />
                </ListItem>
              </>
            )}
          </>
        )}
      </List>
      <Box
        sx={{
          width: "100%",
          paddingLeft: 2,
          paddingRight: 2,
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        <Button
          variant="contained"
          onClick={handleLogout}
          sx={{ width: "100%", paddingTop: 1.5, paddingBottom: 1.5 }}
        >
          Logout
        </Button>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          paddingRight: "0 !important",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
              <img
                src={logo}
                style={{
                  height: "auto",
                  maxHeight: "25px",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IoMdWallet fontSize={24} />
            <Typography sx={{ fontSize: 14, marginLeft: 0.5 }}>
              {contextLoading
                ? "0.00"
                : parseFloat(contextData.Wallet.AvailableBalance).toFixed(2)}
            </Typography>
          </Box>
          {/** <Button variant="outlinedLight" onClick={handleLogout}>
            Logout
          </Button>*/}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: "100%",
          padding: "1rem 1rem",
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default Navigation;
