import axios from "axios";
import { cloneDeep } from "lodash";
import React, { useState, useEffect, useContext } from "react";
import Navigation from "../../components/navigation/Navigation";
import { ApiBaseUrl } from "../../utils/urlbase";
import { dateTimeFormat } from "../../utils/utils";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Helmet from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import { ModalAlert } from "../../components/alert/ModalAlert";
import { GlobalContext } from "../../context/GlobalState";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
  title: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "1rem !important",
    },
  },
}));
export const Ledger = () => {
  const { contextData, contextLoading } = useContext(GlobalContext);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [allUsers, setAllUsers] = useState([]);
  const [ledgerTypes, setLedgerTypes] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [apiLoading, setApiLoading] = useState(false);

  const token = localStorage.getItem("token");
  const params = useParams();

  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const addStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const fetchTransactions = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Wallet.Ledger}/${params.id}/ledger`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTransactions(res.data.data.Transaction);
        setCollection(res.data.data.Transaction);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(transactions);

  useEffect(() => {
    fetchTransactions();
  }, []);

  //Transactions Pagination

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [collection, setCollection] = useState([]);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(transactions);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //Users Pagination
  const [allPage, setAllPage] = useState(0);
  const [rowsPerAllPage, setRowsPerAllPage] = useState(5);
  const [allCollection, setAllCollection] = useState([]);
  const [allSearch, setAllSearch] = useState("");
  const updateAllPage = (p) => {
    setAllPage(p);
    const to = rowsPerAllPage * (p + 1);
    const from = to - rowsPerAllPage;
    setAllCollection(allUsers);
  };

  const handleAllSearch = () => {
    const filtered = cloneDeep(
      allUsers.filter((user) =>
        user.UserName.toLowerCase().includes(allSearch.toLowerCase())
      )
    );
    setAllCollection(filtered);
  };

  const handleChangeAllPage = (event, newPage) => {
    setAllPage(newPage);
  };

  const handleChangeRowsPerAllPage = (event) => {
    setRowsPerAllPage(event.target.value);
    setAllPage(0);
  };

  useEffect(() => {
    if (!allSearch) {
      updateAllPage(0);
    } else {
      handleAllSearch();
    }
  }, [allSearch]);

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  return (
    <Navigation>
      <Helmet>
        <title>Ledger | BMS</title>
      </Helmet>

      <div style={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flex: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h4"
            className={classes.title}
          >
            My Ledger
          </Typography>
        </Box>

        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>TranRefCode</TableCell>
                <TableCell>Description</TableCell>

                <TableCell>Debit</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Balance</TableCell>

                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.length > 0 ? (
                collection
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((transaction, index) => (
                    <TableRow key={index}>
                      <TableCell>{transaction.TranRefCode}</TableCell>
                      <TableCell>{transaction.Description}</TableCell>

                      <TableCell>{transaction.Debit}</TableCell>
                      <TableCell>{transaction.Credit}</TableCell>
                      <TableCell>{transaction.Balance}</TableCell>
                      <TableCell>{dateTimeFormat(transaction.Date)}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No requests yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={transactions.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Card>
      </div>
    </Navigation>
  );
};
