import axios from "axios";
import { cloneDeep } from "lodash";
import React, { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import { ApiBaseUrl } from "../../utils/urlbase";
import { dateTimeFormat } from "../../utils/utils";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Helmet from "react-helmet";
import { makeStyles, useTheme } from "@material-ui/core";
import { FaTimes, FaCheck } from "react-icons/fa";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
  menuPaper: {
    maxHeight: "250px !important",
  },
}));
export const Withdrawals = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [withdrawals, setWithdrawals] = useState([]);
  const token = localStorage.getItem("token");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [error, setError] = useState(false);

  const [disbursement, setDisbursements] = useState([]);

  const [decision, setDecision] = useState("");
  const [channelID, setChannelID] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [remarks, setRemarks] = useState("");

  const [particulars, setParticulars] = useState({
    name: "",
    amount: "",
    code: "",
    date: "",
  });

  const [approve, setApprove] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const handleCloseApprove = () => {
    setOpenApprove(false);
    setApprove({
      status: false,
      error: false,
      msg: "",
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const fetchWithdrawals = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Withdrawal.Withdrawal}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setWithdrawals(res.data.data);
        setCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleApproval = async (e) => {
    e.preventDefault();
    setApprove({
      status: false,
      error: false,
      msg: "",
    });
    setLoading(true);
    await axios
      .get(
        `${
          ApiBaseUrl.MP + ApiBaseUrl.Withdrawal.Withdrawal
        }/${id}/${decision}?DisbursementChannel=${channelID}&TranRefNumber=${
          particulars.code
        }&TranAccountNumber=${accountNo}&TranRemarks=${remarks}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        //setOpenApprove(false);
        setWithdrawals(res);
        setError(false);
        setApprove({
          status: true,
          error: false,
          msg: `Successfully ${decision}.`,
        });
      })
      .catch((err) => {
        setLoading(false);
        //  setOpenApprove(false);
        setError(true);
        console.log(err);
        setApprove({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };

  const fetchDisbursements = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.BaseData.DisbursementChannels}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDisbursements(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDisbursements();
  }, []);

  useEffect(() => {
    fetchWithdrawals();
  }, [loading]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [collection, setCollection] = useState([]);
  const [filter, setFilter] = useState("");

  const [filterVal, setFilterVal] = useState("");

  useEffect(() => {
    if (!search) {
      updatePage(0);
    } else {
      handleSearch();
    }
  }, [search]);

  const handleSearch = () => {
    const filtered = cloneDeep(
      withdrawals.filter(
        (user) =>
          user.FAO.FirstName.toLowerCase().includes(search.toLowerCase()) ||
          user.FAO.LastName.toLowerCase().includes(search.toLowerCase()) ||
          user.TranRef.Code.toLowerCase().includes(search.toLowerCase())
      )
    );
    setCollection(filtered);
  };

  useEffect(() => {
    if (!filterVal) {
      updatePage(0);
    } else {
      handleFilter();
    }
  }, [filterVal]);

  const handleFilter = () => {
    const datesAreOnSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    const filtered = cloneDeep(
      withdrawals.filter(
        (withdrawal) =>
          datesAreOnSameDay(new Date(filterVal), new Date(withdrawal.CreatedAt)) //true
        //game.CreatedAt.toLowerCase().includes(search.toLowerCase())
      )
    );

    setCollection(filtered);
  };

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(withdrawals);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <Navigation>
      <Helmet>
        <title>Withdrawals | BMS</title>
      </Helmet>
      <Modal
        open={openApprove}
        onClose={handleCloseApprove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {approve.status ? null : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Approval
            </Typography>
          )}
          {!approve.status ? (
            <>
              <Box component="div" sx={{ padding: ".5rem 0" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  Are you sure you want to {decision} this request?
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "14px" }}>
                  Name: {particulars.name}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Amount: {particulars.amount}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Transaction Code: {particulars.code}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Date: {particulars.date}
                </Typography>
                <Box sx={{ marginTop: "1rem" }}>
                  <FormControl fullWidth sx={{ marginBottom: ".5rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Disbursement Channels
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Disbursement Channels"
                      id="demo-simple-select"
                      // onChange={(e) => setFilter(e.target.value)}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      onChange={(e) => setChannelID(e.target.value)}
                    >
                      {disbursement.length > 0
                        ? disbursement.map((disbursement, idx) => (
                            <MenuItem value={disbursement.SeqID} key={idx}>
                              {disbursement.LongDesc}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                  <TextField
                    placeholder="Remarks"
                    multiline={true}
                    rows={4}
                    sx={{ width: "100%", marginBottom: ".5rem" }}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Box>
              </Box>
            </>
          ) : approve.error ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaTimes
                fontSize={50}
                color="#F28500"
                sx={{ marginBottom: ".5rem" }}
              />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: "1rem", textAlign: "center" }}
              >
                {approve.msg}
              </Typography>
              <Button
                size="large"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  handleCloseApprove();
                  setApprove({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              >
                Ok
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCheck
                fontSize={50}
                color="#F28500"
                sx={{ marginBottom: ".5rem" }}
              />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: "1rem", textAlign: "center" }}
              >
                {approve.msg}
              </Typography>
              <Button
                size="large"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  handleCloseApprove();
                  setApprove({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              >
                Ok
              </Button>
            </Box>
          )}
          {approve.status ? null : (
            <Grid
              sx={{ margin: ".5rem 0" }}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpenApprove(false)}
                  size="large"
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="large"
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={handleApproval}
                  disabled={loading ? true : false}
                >
                  {decision}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
      <div style={{ width: "100%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Withdrawals
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            sx={{
              width: "100% !important",
              maxWidth: "800px !important",
              marginRight: 1,
            }}
            placeholder="Search name, ref no., number"
            onChange={(e) => setSearch(e.target.value)}
          />
          <Box sx={{ display: "flex" }}>
            <FormControl fullWidth sx={{ marginRight: 1 }}>
              <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
              <Select
                sx={{ width: "97%" }}
                labelId="demo-simple-select-label"
                label="Filter by"
                id="demo-simple-select"
                onChange={(e) => setFilter(e.target.value)}
              >
                <MenuItem value="date_created">Date Created</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              sx={{ width: "50% !important", marginLeft: ".5rem !important" }}
            >
              <DatePicker
                value={!Date.parse(filterVal) ? new Date() : filterVal}
                onChange={(e) => setFilterVal(e)}
                label="Date"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: "50% !important", marginLeft: ".5rem !important" }}
                className={classes.marginSm}
                disabled={filter === "" ? true : false}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full name</TableCell>
                <TableCell>Ref no.</TableCell>
                <TableCell>Mobile no.</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Completed At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawals.length > 0 ? (
                collection
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((withdrawal, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {withdrawal.FAO.FirstName +
                          " " +
                          withdrawal.FAO.MiddleName +
                          " " +
                          withdrawal.FAO.LastName}
                      </TableCell>
                      <TableCell>{withdrawal.TranRef.Code}</TableCell>
                      <TableCell>{withdrawal.FAO.MobileNumber}</TableCell>
                      <TableCell>{withdrawal.Amount}</TableCell>
                      <TableCell>
                        {dateTimeFormat(withdrawal.CreatedAt)}
                      </TableCell>
                      <TableCell>
                        {dateTimeFormat(withdrawal.TranRef.CreatedAt)}
                      </TableCell>
                      <TableCell>{withdrawal.Status.LongName}</TableCell>
                      <TableCell>
                        {withdrawal.WithdrawalDetails.TranRemarks}
                      </TableCell>
                      <TableCell>
                        <Button
                          disabled={
                            withdrawal.Status.LongName === "Processing"
                              ? false
                              : true
                          }
                          variant="contained"
                          onClick={() => {
                            setAccountNo(withdrawal.FAO.MobileNumber);
                            setDecision("approve");
                            setOpenApprove(true);
                            setId(withdrawal.SeqID);
                            setError(false);
                            setParticulars({
                              name:
                                withdrawal.FAO.FirstName +
                                " " +
                                withdrawal.FAO.LastName,
                              amount: withdrawal.Amount,
                              code: withdrawal.TranRef.Code,
                              date: dateTimeFormat(withdrawal.CreatedAt),
                            });
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          disabled={
                            withdrawal.Status.LongName === "Processing"
                              ? false
                              : true
                          }
                          sx={{ margin: ".5rem" }}
                          variant="contained"
                          onClick={() => {
                            setAccountNo(withdrawal.FAO.MobileNumber);
                            setDecision("decline");
                            setOpenApprove(true);
                            setId(withdrawal.SeqID);
                            setError(false);
                            setParticulars({
                              name:
                                withdrawal.FAO.FirstName +
                                " " +
                                withdrawal.FAO.LastName,
                              amount: withdrawal.Amount,
                              code: withdrawal.TranRef.Code,
                              date: dateTimeFormat(withdrawal.CreatedAt),
                            });
                          }}
                        >
                          Decline
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No requests yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={withdrawals.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Card>
      </div>
    </Navigation>
  );
};
