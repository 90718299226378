import React, { useState, useEffect } from 'react';
import { ApiBaseUrl, PageBaseUrl } from './urlbase';

import { styledSplash as Splash } from '../components/splash';
import { Navigate } from "react-router-dom";

const HandleTokenValidation = (callback) => {
    const token = localStorage.getItem('token');

    fetch(ApiBaseUrl.MP + ApiBaseUrl.Auth.ValidateToken, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
    .then(response => response.json())
    .then((res) => {
        callback(res);
    });
}

const PublicRoute = ({children}) => {
    let token = localStorage.getItem("token");

    const [ Authenticated, SetAuthentication ] = useState(false);
    const [ Loading, SetLoading ] = useState(true);

    useEffect(() => {
        HandleTokenValidation((res) => {
            if (res.status) {
                SetAuthentication(true);
            } else {
                localStorage.removeItem('token');
            }
    
            SetLoading(false);
        });
    }, [])
    

    return !Loading ? 
        (
            Authenticated ? <Navigate to={`${PageBaseUrl.Auth.Logout}?prompt=true&next=${PageBaseUrl.Auth.Login}`} /> : children
        ) : <Splash text="Fetching resources"/>;
}

const PrivateRoute = ({children}) => {
    let token = localStorage.getItem("token");

    const [ Authenticated, SetAuthentication ] = useState(false);
    const [ Loading, SetLoading ] = useState(true);

    useEffect(() => {
        HandleTokenValidation((res) => {
            if (res.status) {
                SetAuthentication(true);
            } else {
                localStorage.removeItem('token');
            }
    
            SetLoading(false);
        });
    }, [])
    

    return !Loading ? 
        (
            Authenticated ? children : <Navigate to={`${PageBaseUrl.Auth.Login}?prompt=true&next=${PageBaseUrl.Auth.Login}`} />
        ) : <Splash text="Fetching resources"/>;
}

export { PublicRoute, PrivateRoute, HandleTokenValidation }
