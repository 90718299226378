import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Modal,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Navigation from "../../components/navigation/Navigation";
import Helmet from "react-helmet";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";
import cloneDeep from "lodash/cloneDeep";
import { makeStyles, useTheme } from "@material-ui/core";
import { ModalAlert } from "../../components/alert/ModalAlert";
import { Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import { dateTimeFormat } from "../../utils/utils";
import { MenuItem } from "@mui/material";
import { FaTimes, FaCheck } from "react-icons/fa";
import { InputLabel, Select, FormControl } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Agent() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const token = localStorage.getItem("token");
  const [players, setPlayers] = useState([]);
  const [myPlayers, setMyPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playerID, setPlayerID] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const { getPermissions, contextData, contextLoading } =
    useContext(GlobalContext);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const fetchMyPlayers = async () => {
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}/${
          contextData.AgentID
        }/player`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMyPlayers(res.data.data);
        setMyPlayerCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPlayers = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Agent.Players}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPlayers(res.data.data);
        setCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddPlayer = async (e) => {
    e.preventDefault();
    setLoading(true);

    await axios
      .post(
        `${
          ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent + "/" + contextData.AgentID
        }/player`,
        { UserID: playerID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);

        //setOpenAdd(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "User successfully added.",
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        // setOpenAdd(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was an error with your request.",
        });
      });
  };

  const handleEdit = async (e) => {
    setApiLoading(true);
    const body = {
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      EmailAddress: emailAddress,
      Password: password,
    };
    await axios
      .put(
        `${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}/${
          contextData.SeqID
        }`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //setOpenEdit(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "User successfully edited.",
        });
      })
      .catch((err) => {
        console.log(err);
        //  setOpenEdit(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "There was a problem with your request.",
        });
      });
  };

  useEffect(() => {
    fetchMyPlayers();
  }, [contextLoading, loading]);

  useEffect(() => {
    fetchPlayers();
  }, [loading]);

  useEffect(() => {
    getPermissions(token);
  }, []);

  //Paginate Add Players
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    if (!search) {
      updatePage(0);
    } else {
      handleSearch();
    }
  }, [search]);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(players);
  };

  const handleSearch = () => {
    const filtered = cloneDeep(
      players.filter((user) =>
        user.UserName.toLowerCase().includes(search.toLowerCase())
      )
    );
    setCollection(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //Paginate My Players
  const [myPlayerPage, setMyPlayerPage] = useState(0);
  const [rowsPerMyPlayerPage, setRowsPerMyPlayerPage] = useState(5);
  const [myPlayerSearch, setMyPlayerSearch] = useState("");
  const [myPlayerCollection, setMyPlayerCollection] = useState([]);

  useEffect(() => {
    if (!myPlayerSearch) {
      updateMyPlayerPage(0);
    } else {
      handleMyPlayerSearch();
    }
  }, [search]);

  const updateMyPlayerPage = (p) => {
    setMyPlayerPage(p);
    const to = rowsPerMyPlayerPage * (p + 1);
    const from = to - rowsPerMyPlayerPage;
    setMyPlayerCollection(myPlayers);
  };

  const handleMyPlayerSearch = () => {
    const filtered = cloneDeep(
      myPlayers.filter((user) =>
        user.UserName.toLowerCase().includes(myPlayerSearch.toLowerCase())
      )
    );
    setMyPlayerCollection(filtered);
  };

  const handleChangeMyPlayerPage = (event, newPage) => {
    setMyPlayerPage(newPage);
  };

  const handleChangeRowsPerMyPlayerPage = (event) => {
    setRowsPerMyPlayerPage(event.target.value);
    setMyPlayerPage(0);
  };

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [withdrawals, setWithdrawals] = useState([]);

  const fetchWithdrawals = async () => {
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}/${
          contextLoading ? null : contextData.AgentID
        }/withdrawal`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setWithdrawals(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchWithdrawals();
  }, [contextLoading, loading]);

  const [disbursement, setDisbursements] = useState([]);
  const [openApprove, setOpenApprove] = useState(false);
  const [decision, setDecision] = useState("");
  const [channelID, setChannelID] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState(false);
  const [id, setId] = useState("");

  const [particulars, setParticulars] = useState({
    name: "",
    amount: "",
    code: "",
    date: "",
  });
  const handleCloseApprove = () => {
    setOpenApprove(false);
    setApprove({
      status: false,
      error: false,
      msg: "",
    });
  };

  const handleApproval = async (e) => {
    e.preventDefault();
    setApprove({
      status: false,
      error: false,
      msg: "",
    });
    setLoading(true);
    await axios
      .get(
        `${
          ApiBaseUrl.MP +
          `agent/${contextData.AgentID}/` +
          ApiBaseUrl.Withdrawal.Withdrawal
        }/${id}/${decision}?DisbursementChannel=${channelID}&TranRefNumber=${
          particulars.code
        }&TranAccountNumber=${accountNo}&TranRemarks=${remarks}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        //setOpenApprove(false);
        //setWithdrawals(res);
        setError(false);
        setApprove({
          status: true,
          error: false,
          msg: `Successfully ${decision}.`,
        });
      })
      .catch((err) => {
        setLoading(false);
        //  setOpenApprove(false);
        setError(true);
        console.log(err);
        setApprove({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };
  const fetchDisbursements = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.BaseData.DisbursementChannels}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDisbursements(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchDisbursements();
  }, []);

  const [approve, setApprove] = useState({
    status: false,
    error: false,
    msg: "",
  });

  return (
    <Navigation>
      <Helmet>
        <title>My Agent | BMS</title>
      </Helmet>
      {/**add player modal */}
      <Modal
        open={openAdd}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {!requestStatus.status ? (
            <>
              <h1>Add Player</h1>
              <p>Are you sure you want to add this player?</p>
            </>
          ) : null}
          {!requestStatus.status ? (
            <Grid
              sx={{ margin: ".5rem 0" }}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpenAdd(false)}
                  size="large"
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="large"
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={handleAddPlayer}
                  disabled={loading ? true : false}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          ) : !requestStatus.error ? (
            <ModalAlert
              icon="success"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenAdd(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              icon="error"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenAdd(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>
      {/**edit pofile modal */}
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {!requestStatus.status ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Profile
            </Typography>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    label="First name"
                    variant="outlined"
                    sx={{ width: "100%", margin: ".5rem 0" }}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    label="Middle name"
                    variant="outlined"
                    sx={{ width: "100%", margin: ".5rem 0" }}
                    onChange={(e) => setMiddleName(e.target.value)}
                    value={middleName}
                  />
                </Grid>
              </Grid>
              <TextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                type="email"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setEmailAddress(e.target.value)}
                value={emailAddress}
              />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                type="password"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />

              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    onClick={() => setOpenEdit(false)}
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={handleEdit}
                    disabled={apiLoading ? true : false}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              msg={requestStatus.msg}
              icon="success"
              onPress={() => {
                setOpenEdit(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              msg={requestStatus.msg}
              icon="error"
              onPress={() => {
                setOpenEdit(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>
      {/**Approve/Decline Withdrawal modal*/}
      <Modal
        open={openApprove}
        onClose={handleCloseApprove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {approve.status ? null : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Approval
            </Typography>
          )}
          {!approve.status ? (
            <>
              <Box component="div" sx={{ padding: ".5rem 0" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  Are you sure you want to {decision} this request?
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "14px" }}>
                  Name: {particulars.name}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Amount: {particulars.amount}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Transaction Code: {particulars.code}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  Date: {particulars.date}
                </Typography>
                <Box sx={{ marginTop: "1rem" }}>
                  <FormControl fullWidth sx={{ marginBottom: ".5rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Disbursement Channels
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Disbursement Channels"
                      id="demo-simple-select"
                      // onChange={(e) => setFilter(e.target.value)}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      onChange={(e) => setChannelID(e.target.value)}
                    >
                      {disbursement.length > 0
                        ? disbursement.map((disbursement, idx) => (
                            <MenuItem value={disbursement.SeqID} key={idx}>
                              {disbursement.LongDesc}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                  <TextField
                    placeholder="Remarks"
                    multiline={true}
                    rows={4}
                    sx={{ width: "100%", marginBottom: ".5rem" }}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Box>
              </Box>
            </>
          ) : approve.error ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaTimes
                fontSize={50}
                color="#F28500"
                sx={{ marginBottom: ".5rem" }}
              />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: "1rem", textAlign: "center" }}
              >
                {approve.msg}
              </Typography>
              <Button
                size="large"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  handleCloseApprove();
                  setApprove({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              >
                Ok
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaCheck
                fontSize={50}
                color="#F28500"
                sx={{ marginBottom: ".5rem" }}
              />
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginBottom: "1rem", textAlign: "center" }}
              >
                {approve.msg}
              </Typography>
              <Button
                size="large"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  handleCloseApprove();
                  setApprove({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              >
                Ok
              </Button>
            </Box>
          )}
          {approve.status ? null : (
            <Grid
              sx={{ margin: ".5rem 0" }}
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <Grid item xs={6}>
                <Button
                  onClick={() => setOpenApprove(false)}
                  size="large"
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="large"
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={handleApproval}
                  disabled={loading ? true : false}
                >
                  {decision}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Modal>
      <div style={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Players w/ No Agent" {...a11yProps(0)} />
            <Tab label="Players under me" {...a11yProps(1)} />
            <Tab label="Player Withdrawals" {...a11yProps(2)} />
          </Tabs>
        </Box>

        {/**All players */}
        <TabPanel value={value} index={0}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Players
            </Typography>
            {contextLoading ? null : (
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setOpenEdit(true);

                  setFirstName(contextData.FirstName);
                  setMiddleName(contextData.MiddleName);
                  setLastName(contextData.LastName);
                  setEmailAddress(contextData.EmailAddress);
                  setPassword("");
                }}
              >
                Edit My Profile
              </Button>
            )}
          </Box>
          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile No.</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.length > 0
                  ? collection
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((player, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {player.FirstName +
                              " " +
                              (player.MiddleName === null
                                ? ""
                                : player.MiddleName) +
                              " " +
                              player.LastName}
                          </TableCell>
                          <TableCell>{player.UserName}</TableCell>
                          <TableCell>{player.EmailAddress}</TableCell>
                          <TableCell>{player.MobileNumber}</TableCell>
                          <TableCell>
                            <Button
                              sx={{ margin: "0 0.1rem" }}
                              variant="contained"
                              onClick={() => {
                                setOpenAdd(true);
                                setPlayerID(player.SeqID);
                              }}
                            >
                              Add Player
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
            <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={players.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Card>
        </TabPanel>

        {/**My Players */}
        <TabPanel value={value} index={1}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1.5rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              My Players
            </Typography>
          </Box>
          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile No.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myPlayers.length > 0
                  ? myPlayerCollection
                      .slice(
                        myPlayerPage * rowsPerMyPlayerPage,
                        myPlayerPage * rowsPerMyPlayerPage + rowsPerMyPlayerPage
                      )
                      .map((player, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {player.FirstName +
                              " " +
                              (player.MiddleName === null
                                ? ""
                                : player.MiddleName) +
                              " " +
                              player.LastName}
                          </TableCell>
                          <TableCell>{player.UserName}</TableCell>
                          <TableCell>
                            {parseFloat(player.Wallet.AvailableBalance).toFixed(
                              2
                            )}
                          </TableCell>
                          <TableCell>{player.EmailAddress}</TableCell>
                          <TableCell>{player.MobileNumber}</TableCell>
                        </TableRow>
                      ))
                  : null}
              </TableBody>
            </Table>
            <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={myPlayers.length}
                rowsPerPage={rowsPerMyPlayerPage}
                page={myPlayerPage}
                onPageChange={handleChangeMyPlayerPage}
                onRowsPerPageChange={handleChangeRowsPerMyPlayerPage}
              />
            </TableFooter>
          </Card>
        </TabPanel>

        {/**Player Withdrawals */}
        <TabPanel value={value} index={2}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Withdrawal Requests
            </Typography>
          </Box>
          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full name</TableCell>
                  <TableCell>Ref no.</TableCell>
                  <TableCell>Mobile No.</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Completed At</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Remarks</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {players.length > 0
                  ? /**collection
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )*/
                    withdrawals.map((player, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {player.FAO.FirstName +
                            " " +
                            (player.FAO.MiddleName === null
                              ? ""
                              : player.FAO.MiddleName) +
                            " " +
                            player.FAO.LastName}
                        </TableCell>
                        <TableCell>{player.TranRef.Code}</TableCell>
                        <TableCell>{player.FAO.MobileNumber}</TableCell>
                        <TableCell>{player.Amount}</TableCell>
                        <TableCell>
                          {dateTimeFormat(player.CreatedAt)}
                        </TableCell>

                        <TableCell>
                          {dateTimeFormat(player.TranRef.CreatedAt)}
                        </TableCell>

                        <TableCell>{player.Status.LongName}</TableCell>

                        <TableCell>
                          {player.WithdrawalDetails.TranRemarks}
                        </TableCell>

                        <TableCell>
                          <Button
                            disabled={
                              player.Status.LongName === "Processing"
                                ? false
                                : true
                            }
                            variant="contained"
                            onClick={() => {
                              setAccountNo(player.FAO.MobileNumber);
                              setDecision("approve");
                              setOpenApprove(true);
                              setId(player.SeqID);
                              setError(false);
                              setParticulars({
                                name:
                                  player.FAO.FirstName +
                                  " " +
                                  player.FAO.LastName,
                                amount: player.Amount,
                                code: player.TranRef.Code,
                                date: dateTimeFormat(player.CreatedAt),
                              });
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            disabled={
                              player.Status.LongName === "Processing"
                                ? false
                                : true
                            }
                            sx={{ margin: ".5rem" }}
                            variant="contained"
                            onClick={() => {
                              setAccountNo(player.FAO.MobileNumber);
                              setDecision("decline");
                              setOpenApprove(true);
                              setId(player.SeqID);
                              setError(false);
                              setParticulars({
                                name:
                                  player.FAO.FirstName +
                                  " " +
                                  player.FAO.LastName,
                                amount: player.Amount,
                                code: player.TranRef.Code,
                                date: dateTimeFormat(player.CreatedAt),
                              });
                            }}
                          >
                            Decline
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            {/** <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={players.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>*/}
          </Card>
        </TabPanel>
      </div>
    </Navigation>
  );
}
