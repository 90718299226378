import React from "react";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Navigation from "../../components/navigation/Navigation";
import Helmet from "react-helmet";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";
import { dateTimeFormat } from "../../utils/utils";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Grid,
  FormControlLabel,
} from "@mui/material";
export default function Commissions() {
  let params = useParams();
  const { getPermissions, contextData, contextLoading } =
    useContext(GlobalContext);
  const token = localStorage.getItem("token");
  useEffect(() => {
    getPermissions(token);
  }, [contextLoading]);

  const [history, setHistory] = useState([]);
  const [agent, setAgent] = useState(null);
  const fetchHistory = async () => {
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}/${
          params.userid
        }/commissions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setHistory(res.data.data.History);
        setCollection(res.data.data.History);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAgent = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}/${params.userid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAgent(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchHistory();
    fetchAgent();
  }, [params.userid]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [collection, setCollection] = useState([]);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(history);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <Navigation>
      <Helmet>
        <title>Commissions | BMS</title>
      </Helmet>

      <div style={{ width: "100%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h4"
        >
          {agent !== null ? agent.User.UserName + "'s Commissions" : "..."}
        </Typography>
        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Credit</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.length > 0 ? (
                collection
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((history) => (
                    <TableRow>
                      <TableCell>{history.TranRefCode}</TableCell>
                      <TableCell>{history.Balance}</TableCell>
                      <TableCell>{history.Credit}</TableCell>
                      <TableCell>{history.Description}</TableCell>
                      <TableCell>{dateTimeFormat(history.Date)}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No requests yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={history.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Card>
      </div>
    </Navigation>
  );
}
