export const HFCode = {
  //All users access
  can_read_all_agent_account: "can_read_all_agent_account",
  can_write_all_agent_acount: "can_write_all_agent_acount",
  can_edit_all_users: "can_edit_all_users",
  can_edit_self: "can_edit_self",
  can_read_all_users: "can_read_all_users",
  can_read_self: "can_read_self",
  can_tag_user_as_agent: "can_tag_user_as_agent",
  can_create_user_manually: "can_create_user_manually",
  can_change_user_access: "can_change_user_access",
  can_edit_system_role: "can_edit_system_role",
  can_edit_commission_rate: "can_edit_commission_rate",
  can_assign_role_to_all_users: "can_assign_role_to_all_users",
  can_add_points_to_all_users: " can_add_points_to_all_users",
  //

  //My agent access
  can_read_own_agent_account: "can_read_own_agent_account",
  can_write_own_agent_account: "can_write_own_agent_account",
  can_read_own_agent_account: "can_read_own_agent_account",
  //

  //Games access
  can_encode_game_result: "can_encode_game_result",
  can_create_game: "can_create_game",
  //

  //Betslips access
  // BetSlipsRoute: ["can_read_all_users"],

  //Withdrawal access
  //"can_manage_withdrawal_request"

  //Commission access
  //"can_read_self",

  //Roles access
  //"can_edit_system_role", "can_assign_role_to_all_users","can_view_system_roles","can_create_system_role"

  can_dmcm: "can_dmcm",

  can_manage_withdrawal_request: "can_manage_withdrawal_request",
};

export const AccessControl = {
  SneakPeek: ["can_create_game"],
  UsersRoute: [
    "can_read_all_agent_account",
    "can_write_all_agent_acount",
    "can_edit_all_users",
    "can_read_all_users",
    "can_tag_user_as_agent",
    "can_create_user_manually",
    "can_change_user_access",
    "can_edit_system_role",
    "can_edit_commission_rate",
    "can_assign_role_to_all_users",
    " can_add_points_to_all_users",
  ],
  AgentRoute: ["can_read_own_agent_account", "can_write_own_agent_account"],
  PersonalitiesRoute: ["can_encode_game_result", "can_create_game"],
  GamesRoute: ["can_encode_game_result", "can_create_game"],
  BetSlipsRoute: ["can_read_all_users"],
  WithdrawalsRoute: ["can_manage_withdrawal_request"],
  CommissionsRoute: ["can_read_self"],
  RolesRoute: [
    "can_edit_system_role",
    "can_assign_role_to_all_users",
    "can_view_system_roles",
    "can_create_system_role",
  ],
  AccountingRoute: ["can_dmcm"],
  AuditRoute: ["can_read_audit_logs"],
};
