import React, { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import {
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
  FormControlLabel,
} from "@mui/material";
import Helmet from "react-helmet";
import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";
import cloneDeep from "lodash/cloneDeep";
import { makeStyles, useTheme } from "@material-ui/core";
import { ModalAlert } from "../../components/alert/ModalAlert";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
}));
export const Roles = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const token = localStorage.getItem("token");
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [role, setRole] = useState("1");
  const [apiLoading, setApiLoading] = useState(false);
  const [user, setUser] = useState({ id: "", name: "" });
  const [saveRole, setSaveRole] = useState("");
  console.log(roles);
  const [assignOpen, setAssignOpen] = useState(false);
  const [unassignOpen, setUnassignOpen] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);

  const handleSaveClose = () => {
    setSaveOpen(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const handleAssignClose = () => {
    setAssignOpen(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const handleUnassignClose = () => {
    setUnassignOpen(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const styleAssign = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const fetchRoles = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.System.Roles}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchUsers = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.System.Roles}/${role}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUsers(res.data.data);
        setRoleCollection(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchAllUsers = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllCollection(res.data.data);
        setAllUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssignRole = async () => {
    setApiLoading(true);
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.System.Roles}/${saveRole}/assign/${
          user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        // setSaveOpen(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Successfully assigned role.",
        });
      })
      .catch((err) => {
        console.log(err);
        // setSaveOpen(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };

  const handleUnassignRole = async () => {
    setApiLoading(true);
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.System.Roles}/${user.role}/unassign/${
          user.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        /**  setUser({ id: "", name: "", role: "" });
        setUnassignOpen(false);*/
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Role successfully unassigned.",
        });
      })
      .catch((err) => {
        console.log(err);
        /**   setUser({ id: "", name: "", role: "" });
        setUnassignOpen(false);*/
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };

  useEffect(() => {
    fetchRoles();
    fetchAllUsers();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [role, apiLoading]);

  //All Users Pagination
  const [allPage, setAllPage] = useState(0);
  const [rowsPerAllPage, setRowsPerAllPage] = useState(5);
  const [allCollection, setAllCollection] = useState([]);
  const [allSearch, setAllSearch] = useState("");
  const updateAllPage = (p) => {
    setAllPage(p);
    const to = rowsPerAllPage * (p + 1);
    const from = to - rowsPerAllPage;
    setAllCollection(allUsers);
  };

  const handleAllSearch = () => {
    const filtered = cloneDeep(
      allUsers.filter((user) =>
        user.UserName.toLowerCase().includes(allSearch.toLowerCase())
      )
    );
    setAllCollection(filtered);
  };

  const handleChangeAllPage = (event, newPage) => {
    setAllPage(newPage);
  };

  const handleChangeRowsPerAllPage = (event) => {
    setRowsPerAllPage(event.target.value);
    setAllPage(0);
  };

  useEffect(() => {
    if (!allSearch) {
      updateAllPage(0);
    } else {
      handleAllSearch();
    }
  }, [allSearch]);

  //users specific role pagination

  const [rolePage, setRolePage] = useState(0);
  const [rowsPerRolePage, setRowsPerRolePage] = useState(5);
  const [roleCollection, setRoleCollection] = useState([]);
  const [roleSearch, setRoleSearch] = useState("");
  const updateRolePage = (p) => {
    setRolePage(p);
    const to = rowsPerRolePage * (p + 1);
    const from = to - rowsPerRolePage;
    setRoleCollection(users);
  };

  const handleRoleSearch = () => {
    const filtered = cloneDeep(
      users.filter((user) =>
        user.User.UserName.toLowerCase().includes(roleSearch.toLowerCase())
      )
    );
    setRoleCollection(filtered);
  };

  const handleChangeRolePage = (event, newPage) => {
    setRolePage(newPage);
  };

  const handleChangeRowsPerRolePage = (event) => {
    setRowsPerRolePage(event.target.value);
    setRolePage(0);
  };

  useEffect(() => {
    if (!roleSearch) {
      updateRolePage(0);
    } else {
      handleRoleSearch();
    }
  }, [roleSearch]);

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });
  return (
    <Navigation>
      <Helmet>
        <title>Roles | BMS</title>
      </Helmet>

      {/**All users modal*/}
      <Modal
        open={assignOpen}
        onClose={handleAssignClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assign
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            onChange={(e) => setAllSearch(e.target.value)}
            id="outlined-basic"
            label="Search username"
            variant="outlined"
          />
          <Box component="form" sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers.length > 0 ? (
                  allCollection
                    .slice(
                      allPage * rowsPerAllPage,
                      allPage * rowsPerAllPage + rowsPerAllPage
                    )
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.UserName}</TableCell>
                        <TableCell>
                          <Button
                            size="large"
                            sx={{ width: "100%" }}
                            variant="contained"
                            disabled={apiLoading ? true : false}
                            onClick={() => {
                              setAssignOpen(false);
                              setSaveOpen(true);
                              setUser({
                                id: user.SeqID,
                                name: user.UserName,
                              });
                            }}
                          >
                            Assign
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No requests yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            <TableFooter
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <TablePagination
                sx={{ border: 0 }}
                rowsPerPageOptions={[5, 10, 15]}
                count={allUsers.length}
                rowsPerPage={rowsPerAllPage}
                page={allPage}
                onPageChange={handleChangeAllPage}
                onRowsPerPageChange={handleChangeRowsPerAllPage}
              />
            </TableFooter>
          </Box>
        </Box>
      </Modal>

      {/**Assign Roles Modal */}

      <Modal
        open={saveOpen}
        onClose={handleSaveClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAssign} className={classes.modal}>
          {!requestStatus.status ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Assign Role to{` ${user.name}`}
            </Typography>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  onChange={(e) => setSaveRole(e.target.value)}
                  value={saveRole}
                >
                  {roles.length > 0
                    ? roles.map((role) => (
                        <MenuItem value={role.SeqID}>{role.RoleName}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => setSaveOpen(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={apiLoading ? true : false}
                    onClick={handleAssignRole}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              msg={requestStatus.msg}
              icon="success"
              onPress={() => {
                setSaveOpen(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              msg={requestStatus.msg}
              icon="error"
              onPress={() => {
                setSaveOpen(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>

      {/**Unassign role Modal*/}

      <Modal
        open={unassignOpen}
        onClose={handleUnassignClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleAssign} className={classes.modal}>
          {!requestStatus.status ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Unassign Role to{` ${user.name}`}
            </Typography>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => setSaveOpen(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={apiLoading ? true : false}
                    onClick={handleUnassignRole}
                  >
                    Unassign
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              icon="success"
              msg={requestStatus.msg}
              onPress={() => {
                setUser({ id: "", name: "", role: "" });
                setUnassignOpen(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              icon="error"
              msg={requestStatus.msg}
              onPress={() => {
                setUser({ id: "", name: "", role: "" });
                setUnassignOpen(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>

      <div style={{ width: "100%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Roles
        </Typography>
        <Box sx={{ display: "flex", flex: "wrap" }}>
          <TextField
            onChange={(e) => setRoleSearch(e.target.value)}
            id="outlined-basic"
            label="Search username"
            variant="outlined"
            sx={{ flexGrow: "7" }}
          />
          <FormControl sx={{ marginLeft: ".5rem", flexGrow: "1" }}>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Role"
              onChange={(e) => setRole(e.target.value)}
            >
              {roles.map((role) => (
                <MenuItem value={role.SeqID}>{role.RoleName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{ marginLeft: ".5rem", padding: ".8rem 1rem", flexGrow: "1" }}
            variant="contained"
            onClick={() => setAssignOpen(true)}
          >
            Assign
          </Button>
        </Box>
        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length > 0 ? (
                roleCollection
                  .slice(
                    rolePage * rowsPerRolePage,
                    rolePage * rowsPerRolePage + rowsPerRolePage
                  )
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.User.UserName}</TableCell>
                      <TableCell>
                        {user.User.FirstName + " " + user.User.LastName}
                      </TableCell>
                      <TableCell>{user.Role.RoleName}</TableCell>
                      <TableCell>
                        <Button
                          sx={{
                            marginLeft: ".5rem",
                            padding: ".8rem 1rem",
                            flexGrow: "1",
                          }}
                          variant="contained"
                          onClick={() => {
                            setUnassignOpen(true);
                            setUser({
                              id: user.User.SeqID,
                              name: user.User.UserName,
                              role: user.Role.SeqID,
                            });
                          }}
                          disabled={apiLoading ? true : false}
                        >
                          Unassign
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No requests yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={users.length}
              rowsPerPage={rowsPerRolePage}
              page={rolePage}
              onPageChange={handleChangeRolePage}
              onRowsPerPageChange={handleChangeRowsPerRolePage}
            />
          </TableFooter>
        </Card>
      </div>
    </Navigation>
  );
};
