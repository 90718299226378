import React, { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import { ApiBaseUrl } from "../../utils/urlbase";
import axios from "axios";
import Helmet from "react-helmet";
import {
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
  FormControlLabel,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { makeStyles, useTheme } from "@material-ui/core";
import { ModalAlert } from "../../components/alert/ModalAlert";

const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
}));
export const Personalities = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const [personalities, setPersonalities] = useState([]);
  const [personalitiesCollection, setPersonalitiesCollection] = useState([]);
  const [personalitiesPage, setPersonalitiesPage] = useState(0);
  const [rowsPerPersonalitiesPage, setRowsPerPersonalitiesPage] = useState(7);
  const [personalitiesSearch, setPersonalitiesSearch] = useState("");

  const [personalityId, setPersonalityId] = useState("");
  const [shortName, setShortName] = useState("");
  const [longName, setLongName] = useState("");
  const [categoryIdentifier, setCategoryIdentifier] = useState("");

  const [openAdd, setOpenAdd] = useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const [openEdit, setOpenEdit] = useState(false);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const fetchPersonalities = async () => {
    const token = localStorage.getItem("token");

    await axios
      .get(ApiBaseUrl.MP + ApiBaseUrl.BaseData.Personalities, {
        Authorization: `Bearer ${token}`,
      })
      .then((res) => {
        setPersonalities(res.data.data);
        setPersonalitiesCollection(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchPersonalities();
  }, [loading]);

  const updatePersonalitiesPage = (p) => {
    setPersonalitiesPage(p);
    const to = rowsPerPersonalitiesPage * (p + 1);
    const from = to - rowsPerPersonalitiesPage;
    setPersonalitiesCollection(personalities);
  };

  const handlePersonalitiesSearch = () => {
    const filtered = cloneDeep(
      personalities.filter((person) =>
        person.LongName.toLowerCase().includes(
          personalitiesSearch.toLowerCase()
        )
      )
    );
    setPersonalitiesCollection(filtered);
  };

  const handleChangePersonalitiesPage = (event, newPage) => {
    setPersonalitiesPage(newPage);
  };

  const handleChangeRowsPerPersonalitiesPage = (event) => {
    setRowsPerPersonalitiesPage(event.target.value);
    setPersonalitiesPage(0);
  };

  useEffect(() => {
    if (!personalitiesSearch) {
      updatePersonalitiesPage(0);
    } else {
      handlePersonalitiesSearch();
    }
  }, [personalitiesSearch]);

  const handleAddPersonality = async () => {
    if (shortName === "" || longName === "") {
      setLoading(false);
      return;
    }
    setLoading(true);

    const body = {
      ShortName: shortName,
      LongName: longName,
    };
    await axios
      .post(`${ApiBaseUrl.MP + ApiBaseUrl.BaseData.Personalities}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Team/Player successfully added.",
        });
        setPersonalityId("");
        setLongName("");
        setShortName("");
        setCategoryIdentifier("");
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
        console.log(err);
        setPersonalityId("");
        setLongName("");
        setShortName("");
        setCategoryIdentifier("");
      });
  };

  const handleUpdatePersonality = async () => {
    if (shortName === "" || longName === "") {
      setLoading(false);
      return;
    }

    setLoading(true);

    const body = {
      ShortName: shortName,
      LongName: longName,
    };

    await axios
      .put(
        `${ApiBaseUrl.MP + ApiBaseUrl.BaseData.Personalities}` +
          `/${personalityId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Team/Player successfully updated.",
        });
        setPersonalityId("");
        setLongName("");
        setShortName("");
        setCategoryIdentifier("");
        setPersonalityId("");
      })
      .catch((err) => {
        setLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
        setPersonalityId("");
        setLongName("");
        setShortName("");
        setCategoryIdentifier("");
        setPersonalityId("");
      });
  };

  return (
    <Navigation>
      <Helmet>
        <title>Teams & Players | BMS</title>
      </Helmet>

      {/**Add Personality */}
      <Modal
        open={openAdd}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {!requestStatus.status ? (
            <Box sx={{ marginBottom: ".2rem" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add Team or Player
              </Typography>
              <Typography id="modal-modal-title" component="p">
                Full Name & Short Name should be UNIQUE.
              </Typography>
            </Box>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setLongName(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Short Name"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setShortName(e.target.value)}
              />
              {/**   <TextField
                id="outlined-basic"
                label="Category Identifier"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setCategoryIdentifier(e.target.value)}
              />*/}
              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => {
                      setOpenAdd(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={handleAddPersonality}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              icon="success"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenAdd(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              icon="error"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenAdd(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>

      {/**Edit Personality */}
      <Modal
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {!requestStatus.status ? (
            <Box sx={{ marginBottom: ".2rem" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit Team or Player
              </Typography>
              <Typography id="modal-modal-title" component="p">
                Full Name & Short Name should be UNIQUE.
              </Typography>
            </Box>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setLongName(e.target.value)}
                value={longName}
              />
              <TextField
                id="outlined-basic"
                label="Short Name"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setShortName(e.target.value)}
                value={shortName}
              />
              {/** <TextField
                id="outlined-basic"
                label="Category Identifier"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setCategoryIdentifier(e.target.value)}
                value={categoryIdentifier}
              />*/}
              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => {
                      setOpenEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={loading ? true : false}
                    onClick={handleUpdatePersonality}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              icon="success"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenEdit(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              icon="error"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenAdd(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>

      <div style={{ width: "100%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Teams & Players
        </Typography>
        <Box sx={{ display: "flex", flex: "wrap" }}>
          <TextField
            id="outlined-basic"
            label="Search Name"
            variant="outlined"
            sx={{ flexGrow: "7" }}
            onChange={(e) => setPersonalitiesSearch(e.target.value)}
          />

          <Button
            sx={{ marginLeft: ".5rem", padding: ".8rem 1rem", flexGrow: "1" }}
            variant="contained"
            onClick={() => {
              setOpenAdd(true);
            }}
          >
            Add
          </Button>
        </Box>
        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Short Name</TableCell>
                {/** <TableCell>League</TableCell> */}
                <TableCell>Active</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {personalities.length > 0 ? (
                personalitiesCollection
                  .slice(
                    personalitiesPage * rowsPerPersonalitiesPage,
                    personalitiesPage * rowsPerPersonalitiesPage +
                      rowsPerPersonalitiesPage
                  )
                  .map((personality, index) => (
                    <TableRow key={index}>
                      <TableCell>{personality.LongName}</TableCell>
                      <TableCell>{personality.ShortName}</TableCell>
                      {/** <TableCell>{personality.CategoryIdentifier}</TableCell>*/}
                      <TableCell>
                        {personality.IsActive ? "Active" : "Offline"}
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{
                            marginLeft: ".5rem",
                            padding: ".8rem 1rem",
                            flexGrow: "1",
                          }}
                          variant="contained"
                          onClick={() => {
                            setPersonalityId(personality.SeqID);
                            setOpenEdit(true);
                            setLongName(personality.LongName);
                            setShortName(personality.ShortName);
                            setCategoryIdentifier(
                              personality.CategoryIdentifier
                            );
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No teams & players yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={personalities.length}
              rowsPerPage={rowsPerPersonalitiesPage}
              page={personalitiesPage}
              onPageChange={handleChangePersonalitiesPage}
              onRowsPerPageChange={handleChangeRowsPerPersonalitiesPage}
            />
          </TableFooter>
        </Card>
      </div>
    </Navigation>
  );
};
