import axios from "axios";
import { cloneDeep } from "lodash";
import React, { useState, useEffect } from "react";
import Navigation from "../../components/navigation/Navigation";
import { ApiBaseUrl } from "../../utils/urlbase";
import { dateTimeFormat } from "../../utils/utils";
import { IoReloadCircleSharp } from "react-icons/io5";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Modal,
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Helmet from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";
import { ModalAlert } from "../../components/alert/ModalAlert";
import { Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
  title: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "1rem !important",
    },
  },
  dateInput: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "1px !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Accounting = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [allUsers, setAllUsers] = useState([]);
  const [ledgerTypes, setLedgerTypes] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [usersOpen, setUsersOpen] = useState(false);
  const [memoOpen, setMemoOpen] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const handleUsersClose = () => {
    setUsersOpen(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const handleMemoClose = () => {
    setMemoOpen(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const token = localStorage.getItem("token");
  const params = useParams();

  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const addStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const fetchUsers = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllUsers(res.data.data);
        setAllCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTransactions = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Wallet.Ledger}/${params.id}/ledger`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTransactions(res.data.data.Transaction);
        setCollection(res.data.data.Transaction);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchLedgerTypes = async () => {
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.BaseData.LedgerTypes}`,
        { CanMemo: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLedgerTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMemo = async () => {
    setApiLoading(true);
    await axios
      .post(
        `${ApiBaseUrl.MP + ApiBaseUrl.Financials.Memo}/${params.id}`,
        {
          LedgerEntryType: type,
          Amount: amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        //  setMemoOpen(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Memo successfully added",
        });
      })
      .catch((err) => {
        console.log(err);
        //  setMemoOpen(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };

  useEffect(() => {
    fetchUsers();
    fetchTransactions();
    fetchLedgerTypes();
  }, [params.id]);

  useEffect(() => {
    fetchTransactions();
  }, [apiLoading]);

  //Transactions Pagination

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [collection, setCollection] = useState([]);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(transactions);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //Users Pagination
  const [allPage, setAllPage] = useState(0);
  const [rowsPerAllPage, setRowsPerAllPage] = useState(5);
  const [allCollection, setAllCollection] = useState([]);
  const [allSearch, setAllSearch] = useState("");
  const updateAllPage = (p) => {
    setAllPage(p);
    const to = rowsPerAllPage * (p + 1);
    const from = to - rowsPerAllPage;
    setAllCollection(allUsers);
  };

  const handleAllSearch = () => {
    const filtered = cloneDeep(
      allUsers.filter((user) =>
        user.UserName.toLowerCase().includes(allSearch.toLowerCase())
      )
    );
    setAllCollection(filtered);
  };

  const handleChangeAllPage = (event, newPage) => {
    setAllPage(newPage);
  };

  const handleChangeRowsPerAllPage = (event) => {
    setRowsPerAllPage(event.target.value);
    setAllPage(0);
  };

  useEffect(() => {
    if (!allSearch) {
      updateAllPage(0);
    } else {
      handleAllSearch();
    }
  }, [allSearch]);

  //utils
  const currentUserName = () => {
    return allUsers.length > 0
      ? allUsers
          .filter((user) => user.SeqID.toString() === params.id.toString())
          .map((user) => user.UserName)[0]
      : null;
  };

  const [incomeReport, setIncomeReport] = useState([]);

  const fetchIncomeReport = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Financials.Reports}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIncomeReport(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchIncomeReport();
  }, []);

  console.log(incomeReport);

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [dateFilter, setDateFilter] = useState("");
  console.log(dateFilter);
  const filterDateReports = async () => {
    const fromDate = new Date(from);
    const fromDateFormat =
      fromDate.getFullYear() +
      "-" +
      (fromDate.getMonth() + 1) +
      "-" +
      fromDate.getDate();
    const toDate = new Date(to);
    const toDateFormat =
      toDate.getFullYear() +
      "-" +
      (toDate.getMonth() + 1) +
      "-" +
      toDate.getDate();

    await axios
      .get(
        `${
          ApiBaseUrl.MP +
          ApiBaseUrl.Financials.Reports +
          `?enableDateOrdering=true&date=["${fromDateFormat}","${toDateFormat}"]&dateColumn=CreatedAt&orderMode=asc`
        }  `,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setIncomeReport(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (to || from) {
      filterDateReports();
    }
  }, [from, to]);

  return (
    <Navigation>
      <Helmet>
        <title>Accounting | BMS</title>
      </Helmet>
      {/**All users modal */}
      <Modal
        open={usersOpen}
        onClose={handleUsersClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assign
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            onChange={(e) => setAllSearch(e.target.value)}
            id="outlined-basic"
            label="Search username"
            variant="outlined"
          />
          <Box component="form" sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers.length > 0 ? (
                  allCollection
                    .slice(
                      allPage * rowsPerAllPage,
                      allPage * rowsPerAllPage + rowsPerAllPage
                    )
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.UserName}</TableCell>
                        <TableCell>
                          <Button
                            size="large"
                            sx={{ width: "100%" }}
                            variant="contained"
                            disabled={apiLoading ? true : false}
                            onClick={() => {
                              navigate(`/accounting/${user.SeqID}`);
                              setUsersOpen(false);
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No requests yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            <TableFooter
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <TablePagination
                sx={{ border: 0 }}
                rowsPerPageOptions={[5, 10, 15]}
                count={allUsers.length}
                rowsPerPage={rowsPerAllPage}
                page={allPage}
                onPageChange={handleChangeAllPage}
                onRowsPerPageChange={handleChangeRowsPerAllPage}
              />
            </TableFooter>
          </Box>
        </Box>
      </Modal>

      {/**Memo modal */}
      <Modal
        open={memoOpen}
        onClose={handleMemoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={addStyle} className={classes.modal}>
          {!requestStatus.status ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Memo for {currentUserName()}
            </Typography>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Ledger type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Ledger type"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                >
                  {ledgerTypes.length > 0
                    ? ledgerTypes
                        .filter((type) => type.CanMemo !== false)
                        .map((type) => (
                          <MenuItem value={type.SeqID}>
                            {type.LongName}
                          </MenuItem>
                        ))
                    : null}
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                sx={{ width: "100%", margin: ".5rem 0" }}
                onChange={(e) => setAmount(e.target.value)}
              />
              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => setMemoOpen(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={apiLoading ? true : false}
                    onClick={handleMemo}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              icon="success"
              msg={requestStatus.msg}
              onPress={() => {
                setMemoOpen(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              icon="error"
              msg={requestStatus.msg}
              onPress={() => {
                setMemoOpen(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>

      <div style={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="General Accounting" {...a11yProps(0)} />
            <Tab label="Users Accounting" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  marginTop: 2,
                  marginBottom: 2,
                }}
                variant="h4"
                className={classes.title}
              >
                General Accounting
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center " }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date From"
                    value={from}
                    onChange={(e) => setFrom(e)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                    }}
                    className={classes.dateInput}
                  />
                </LocalizationProvider>
                <Typography sx={{ mx: 1 }}>to</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date To"
                    value={to}
                    onChange={(e) => setTo(e)}
                    renderInput={(params) => <TextField {...params} />}
                    className={classes.marginSm}
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  setFrom(null);
                  setTo(null);
                  fetchIncomeReport();
                }}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  flexGrow: 1,
                  marginLeft: 2,
                }}
              >
                <IoReloadCircleSharp fontSize={36} />
              </Button>
            </Box>
          </Box>
          <Box sx={{ marginTop: 2 }}>
            <Typography>
              Total Income:{" "}
              {parseFloat(incomeReport?.Summary?.Income).toFixed(2)}
            </Typography>
            <Typography>
              Total Tax: {parseFloat(incomeReport?.Summary?.Tax).toFixed(2)}
            </Typography>
          </Box>
          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Game Desc</TableCell>
                  <TableCell>Income</TableCell>
                  <TableCell>Tax</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incomeReport?.Game?.length > 0 ? (
                  incomeReport.Game.map((report, index) => (
                    <TableRow key={index}>
                      <TableCell>{report.GameDesc}</TableCell>
                      <TableCell>
                        {parseFloat(report.Report.Income).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {parseFloat(report.Report.Tax).toFixed(2)}
                      </TableCell>
                      <TableCell>{dateTimeFormat(report.CreatedAt)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No requests yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              flex: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
              className={classes.title}
            >
              Accounting History {currentUserName()}
            </Typography>

            <Box sx={{ display: "flex", flex: "wrap" }}>
              <Button
                sx={{ marginLeft: ".5rem", padding: ".8rem 1rem" }}
                variant="contained"
                onClick={() => {
                  setUsersOpen(true);
                }}
              >
                Select User
              </Button>
              <Button
                sx={{ marginLeft: ".5rem", padding: ".8rem 1rem" }}
                variant="contained"
                onClick={() => {
                  setMemoOpen(true);
                }}
              >
                Add Memo
              </Button>
            </Box>
          </Box>
          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>TranRefCode</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Debit</TableCell>
                  <TableCell>Credit</TableCell>

                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.length > 0 ? (
                  collection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transaction, index) => (
                      <TableRow key={index}>
                        <TableCell>{transaction.TranRefCode}</TableCell>
                        <TableCell>{transaction.Description}</TableCell>
                        <TableCell>{transaction.Balance}</TableCell>
                        <TableCell>{transaction.Debit}</TableCell>
                        <TableCell>{transaction.Credit}</TableCell>

                        <TableCell>
                          {dateTimeFormat(transaction.Date)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No requests yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Card>
        </TabPanel>
      </div>
    </Navigation>
  );
};
