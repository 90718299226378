import React, { useState, useEffect, useContext } from "react";
import Navigation from "../../components/navigation/Navigation";
import Helmet from "react-helmet";
import axios from "axios";
import { cloneDeep } from "lodash";
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Modal,
  TableBody,
  TableFooter,
  TablePagination,
  Grid,
} from "@mui/material";
import { ApiBaseUrl } from "../../utils/urlbase";
import { ModalAlert } from "../../components/alert/ModalAlert";
import { makeStyles, useTheme } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
  menuPaper: {
    maxHeight: "250px !important",
  },
  title: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "2rem !important",
    },
  },
  stack: {
    [theme.breakpoints.only("xs")]: {
      display: "flex !important",
      flexDirection: "column !important",
      justifyContent: "flex-start !important",
      alignItems: "start !important",
    },
  },
}));
export const FundTransfer = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const token = localStorage.getItem("token");
  const [usersOpen, setUsersOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const handleUsersClose = () => setUsersOpen(false);
  const handleConfirmationClose = () => {
    setOpenConfirmation(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const fetchUsers = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAllUsers(res.data.data);
        setAllCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [allPage, setAllPage] = useState(0);
  const [rowsPerAllPage, setRowsPerAllPage] = useState(5);
  const [allCollection, setAllCollection] = useState([]);
  const [allSearch, setAllSearch] = useState("");
  const updateAllPage = (p) => {
    setAllPage(p);
    const to = rowsPerAllPage * (p + 1);
    const from = to - rowsPerAllPage;
    setAllCollection(allUsers);
  };

  const handleAllSearch = () => {
    const filtered = cloneDeep(
      allUsers.filter((user) =>
        user.UserName.toLowerCase().includes(allSearch.toLowerCase())
      )
    );
    setAllCollection(filtered);
  };

  const handleChangeAllPage = (event, newPage) => {
    setAllPage(newPage);
  };

  const handleChangeRowsPerAllPage = (event) => {
    setRowsPerAllPage(event.target.value);
    setAllPage(0);
  };

  useEffect(() => {
    if (!allSearch) {
      updateAllPage(0);
    } else {
      handleAllSearch();
    }
  }, [allSearch]);

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    uid: "",
    username: "",
  });
  const [amount, setAmount] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const handleTransfer = async () => {
    setApiLoading(true);
    if (
      user.firstname === "" ||
      user.lastname === "" ||
      user.uid === "" ||
      user.username === "" ||
      user.amount === ""
    ) {
      setApiLoading(false);
      return;
    }
    const body = {
      DestinationAccount: user.uid,
      TransferAmount: amount,
    };
    await axios
      .post(ApiBaseUrl.MP + ApiBaseUrl.Financials.FundTrasnfer, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Fund transfered successfully!",
        });
        setUser({
          firstname: "",
          lastname: "",
          uid: "",
          username: "",
        });
        setAmount("");
      })
      .catch((err) => {
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
        setUser({
          firstname: "",
          lastname: "",
          uid: "",
          username: "",
        });
        setAmount("");
      });
  };

  return (
    <Navigation>
      <Helmet>
        <title>Fund Transfer | BMS</title>
      </Helmet>
      {/**Users modal */}
      <Modal
        open={usersOpen}
        onClose={handleUsersClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "1rem" }}
          >
            Select User
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            onChange={(e) => setAllSearch(e.target.value)}
            id="outlined-basic"
            label="Search username"
            variant="outlined"
          />
          <Box component="form" sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers.length > 0 ? (
                  allCollection
                    .slice(
                      allPage * rowsPerAllPage,
                      allPage * rowsPerAllPage + rowsPerAllPage
                    )
                    .map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.UserName}</TableCell>
                        <TableCell>
                          <Button
                            size="large"
                            sx={{ width: "100%" }}
                            variant="contained"
                            onClick={() => {
                              setUser({
                                firstname: user.FirstName,
                                lastname: user.LastName,
                                uid: user.SeqID,
                                username: user.UserName,
                              });
                              setUsersOpen(false);
                            }}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No requests yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            <TableFooter
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <TablePagination
                sx={{ border: 0 }}
                rowsPerPageOptions={[5, 10, 15]}
                count={allUsers.length}
                rowsPerPage={rowsPerAllPage}
                page={allPage}
                onPageChange={handleChangeAllPage}
                onRowsPerPageChange={handleChangeRowsPerAllPage}
              />
            </TableFooter>
          </Box>
        </Box>
      </Modal>

      {/**Confirmation modal */}
      <Modal
        open={openConfirmation}
        onClose={handleConfirmationClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          {!requestStatus.status ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Transfer funds
            </Typography>
          ) : null}
          {!requestStatus.status ? (
            <Box component="form">
              <Typography>
                Are you sure you want to tranfser these funds?
              </Typography>

              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => setOpenConfirmation(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={apiLoading ? true : false}
                    onClick={handleTransfer}
                  >
                    Transfer
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : !requestStatus.error ? (
            <ModalAlert
              icon="success"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenConfirmation(false);
                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          ) : (
            <ModalAlert
              icon="error"
              msg={requestStatus.msg}
              onPress={() => {
                setOpenConfirmation(false);

                setRequestStatus({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          )}
        </Box>
      </Modal>

      <div
        style={{
          width: "100%",

          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "800px" }}>
          <Paper elevation={3} sx={{ padding: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                flex: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
              className={classes.stack}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
                variant="h4"
                className={classes.title}
              >
                Fund Transfer
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  setUsersOpen(true);
                }}
                sx={{ padding: ".7rem", margin: ".5rem 0" }}
              >
                Select User
              </Button>
            </Box>
            <Box
              sx={{
                marginBottom: "1rem",
                display: "flex",
                flexDirection: "row",
              }}
              className={classes.stack}
            >
              <Typography sx={{ marginRight: "1rem" }}>
                Name:{" "}
                {user.firstname === "" && user.lastname === ""
                  ? "Please select a user"
                  : user.firstname + " " + user.lastname}
              </Typography>
              <Typography>
                Username:{" "}
                {user.username === "" ? "Please select a user" : user.username}
              </Typography>
            </Box>
            <TextField
              sx={{ marginBottom: "1rem", width: "100%" }}
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Button
              sx={{ width: "100%", padding: "1rem" }}
              variant={"contained"}
              onClick={() => {
                setOpenConfirmation(true);
              }}
            >
              Tranfer Funds
            </Button>
          </Paper>
        </Box>
      </div>
    </Navigation>
  );
};
