import React, { useState } from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  Grid,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import loginBg from "../../media/images/signinbg.png";
import logo from "../../media/images/oddictslogopure.svg";
import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
export const Login = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      UserName: username,
      Password: password,
    };
    console.log(body);
    setLoginError(false);
    setLoading(true);
    await axios
      .post(`${ApiBaseUrl.MP + ApiBaseUrl.Auth.CreateToken}`, body)
      .then((res) => {
        localStorage.setItem("token", res.data.data.access_token);
        setLoginError(false);
        setLoading(false);
        return navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setLoginError(true);
      });
  };

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>Login | BMS</title>
      </Helmet>
      <Grid item lg={4} md={6} xs={12} sx={{ padding: "4rem" }}>
        <Box
          component="div"
          sx={{
            marginBottom: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            style={{ height: "auto", width: "100%", maxWidth: "300px" }}
          />
          <Typography sx={{ marginTop: "1rem", opacity: ".7" }}>
            Sign in to your account.
          </Typography>
        </Box>
        {loginError ? (
          <Typography
            sx={{
              color: "error.main",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Incorrect username/password.
          </Typography>
        ) : null}
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="Username"
            sx={{ marginBottom: "1rem" }}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <FormControl
            variant="outlined"
            sx={{ marginBottom: "1.5rem" }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <Button
            variant="contained"
            size="large"
            sx={{ padding: ".8rem 0" }}
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress color="inherit" size={30} /> : "Login"}
          </Button>
         {/**  <Typography sx={{ marginTop: "1rem", textAlign: "center" }}>
            Don't have an account? <Button>Sign Up Here</Button>
          </Typography>*/}
        </Box>
      </Grid>
    </Grid>
  );
};
