const ApiBaseUrl = {
  MP: "https://managementapi-uat.oddicts.com/api/v1/",
  BP: "https://bettorapi-test.oddicts.com/api/v1/",

  Auth: {
    CreateToken: "auth/token",
    ValidateToken: "auth/validatetoken",
    CreateOTP: "auth/register/otp",
    ValidateOTP: "auth/register/validate",
    Register: "auth/register",
    ValidateRegisterToken: "auth/register/checkvalidity",
    GetPermissions: "auth/me",
  },
  Game: {
    FetchAllGames: "event/game",
    CreateGame: "event/game",
    EncodeGame: "event/game",
  },
  Wallet: {
    Balance: "wallet/balance",
    History: "wallet/history",
    Ledger: "wallet",
  },
  BaseData: {
    Personalities: "personalities",
    LedgerTypes: "ledgerentrytypes",
    DisbursementChannels: "disbursementchannels",
    OddTypes: "gameoddtypes",
  },
  Users: {
    FetchAllUsers: "user",
  },
  Financials: {
    AddPoints: "financials/imf/move",
    Memo: "financials/memo",
    FundTrasnfer: "financials/fundtransfer",
    Game: "financials/reports/game",
    Reports: "financials/reports/income",
  },
  Withdrawal: {
    Withdrawal: "withdrawal",
  },
  Agent: {
    Agent: "agent",
    Players: "agent/players",
  },
  System: {
    Roles: "system/role",
  },
  AuditTrail: {
    LoginHistory: "logtrail/login",
    TranHistory: "logtrail/transactions",
  },
};

const PageBaseUrl = {
  Auth: {
    Login: "/login",
    Register: {
      OTP: "/register/otp",
      Create: "/register/:token/create",
    },
    Logout: "/logout",
  },
};

export { ApiBaseUrl, PageBaseUrl };
