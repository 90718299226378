import date from "date-and-time";

export const UNIXConverter = (datetime) => {
  const milliseconds = datetime;
  const dateObject = new Date(milliseconds);
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const hour = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();
  const humanDateFormat =
    year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
  return humanDateFormat;
};

/**export const dateTimeFormat = (res_date) => {
  const date = new Date(res_date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const midday = parseInt(date.getHours()) >= 12 ? "PM" : "AM";

  const formatted =
    date.getDate() +
    " " +
    months[date.getMonth()] +
    " " +
    date.getFullYear() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    " " +
    midday;
  return formatted.toString();
};*/

const timeFormat = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? "" : "";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};

/**export const dateTimeFormat = (res_date) => {
  const date = new Date(res_date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //const midday = timeFormat(date.getHours() + ":" + date.getMinutes());
  const midday = parseInt(date.getHours()) >= 12 ? "PM" : "AM";

  const formatted =
    timeFormat(date.getHours() + ":" + date.getMinutes()) +
    " " +
    midday.toLowerCase() +
    " " +
    months[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear() +
    " ";

  return formatted.toString();
};*/

export const dateTimeFormat = (res_date) => {
  const newDate = new Date(res_date);
  const dateTime = date.format(newDate, "hh:mm A MMM DD YYYY");
  return dateTime;
};

export const checkForSpecialChar = (string) => {
  let spChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (spChars.test(string)) {
    return true;
  } else {
    return false;
  }
};

export const hasNumber = (string) => {
  return /\d/.test(string);
};

export const getHandicapIsLocked = (game) => {
  return game.GroupSelector.filter(
    (name) => name.GroupSelectorName === "Team"
  ).map(
    (game) =>
      game.Options.filter(
        (name) =>
          name.Personality.ShortName === game.Options[0].Personality.ShortName
      ).map((game) => game.IsRecordLocked)[0]
  )[0];
};
