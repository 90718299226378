import React, { createContext, useReducer } from "react";
import axios from "axios";
import AppReducer from "./AppReducer";
import { ApiBaseUrl } from "../utils/urlbase";
const initalState = {
  data: [],
  error: null,
  loading: true
};

export const GlobalContext = createContext(initalState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initalState);
  const token = localStorage.getItem("token");
  // Actions
  const getPermissions = async (token) => {
  
    try {
      
      const res = await axios.get(`${ApiBaseUrl.MP + ApiBaseUrl.Auth.GetPermissions}`, {
          headers:{
            Authorization: `Bearer ${token}`,
          }
      });
      dispatch({
        type: "GET_PERMISSIONS",
        payload: res.data.data
      });
    } catch (err) {
      dispatch({
        type: "PERMISSIONS_ERROR",
        payload: err
      });
    }
  }



  return (
    <GlobalContext.Provider
      value={{
        contextData: state.data,
        error: state.error,
        contextLoading: state.loading,
        getPermissions 
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};