import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Paper, Box, Typography, CircularProgress } from '@mui/material';
import loginBg from "../media/images/luke-chesser-tgrBcf7S_dY-unsplash.jpg";
import { Helmet } from 'react-helmet';

import '../css/main.css';

class Splash extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Helmet>
                    <title>Initializing | BMS</title>
                </Helmet>
                <Paper sx={{
                    display: 'flex',
                    height: '100vh',
                    width: '100%',
                    background: `#fff`,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        textAlign: 'center'
                    }}>
                        <Typography sx={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            paddingX: 2,
                            paddingY: 0.8,
                            background: '#F28500',
                            color: '#fff',
                            display: 'inline-block',
                            borderRadius: 0,
                            marginRight: 1.5
                        }}>
                            BMS
                        </Typography>
                        <Typography sx={{
                            display: 'inline-block',
                            fontSize: 14,
                            lineHeight: 1,
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>
                            Betting Management System
                        </Typography>
                        <Box sx={{
                            marginTop: 2
                        }}>
                            <CircularProgress />
                        </Box>
                    </Box>
                </Paper>
            </Fragment>
        );
    }
}

const styles = (theme) => ({
    
});

const styledSplash = withStyles(styles, { withTheme: true })(Splash);

export { styledSplash };