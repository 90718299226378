import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  TextField,
  Button,
  Select,
  MenuItem,
  Modal,
  Box,
  Grid,
  FormControlLabel,
  Switch,
  OutlinedInput,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import Navigation from "../../components/navigation/Navigation";
import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";

import Helmet from "react-helmet";
import AddIcon from "@mui/icons-material/Add";
import { checkForSpecialChar, hasNumber } from "../../utils/utils";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { GlobalContext } from "../../context/GlobalState";
import { HFCode } from "../../utils/hfcode";
import { useNavigate } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core";
import { ModalAlert } from "../../components/alert/ModalAlert";
import { Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Users = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const token = localStorage.getItem("token");
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [collection, setCollection] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [openCommission, setOpenCommission] = useState(false);

  const handleCloseCommission = () => {
    setOpenCommission(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const { getPermissions, contextData, contextLoading } =
    useContext(GlobalContext);
  /**  useEffect(() => {
    if (!contextLoading) {
      if (!contextData.Permissions.HFCode.includes(HFCode.can_edit_all_users)) {
        return navigate("/");
      }
    }
  }, [contextLoading]);*/

  useEffect(() => {
    getPermissions(token);
  }, [contextLoading]);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => {
    setOpenAdd(false);
    setPointsError(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const [rows, setRows] = useState([]);

  const handleOpenEdit = (id, firstname, lastname, phoneno, points) => {
    setOpenEdit(true);
  };
  const handleCloseEdit = (id, firstname, lastname, phoneno, points) => {
    setEditError(false);
    setOpenEdit(false);
    setPassword("");
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const fetchUsers = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //console.log(res.data.data);
        setRows(res.data.data);
        setCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    fetchUsers();
  }, [loading]);

  useEffect(() => {
    if (!search) {
      updatePage(0);
    } else {
      handleSearch();
    }
  }, [search]);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(rows);
  };

  const handleSearch = () => {
    const filtered = cloneDeep(
      rows.filter((user) =>
        user.UserName.toLowerCase().includes(search.toLowerCase())
      )
    );
    setCollection(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);
  const [bettor, setBettor] = useState(false);
  const [editError, setEditError] = useState(false);
  const setEditUser = (
    id,
    firstname,
    lastname,
    middlename,
    email,
    phone,

    admin,
    bettor
  ) => {
    setUserId(id);
    setFirstName(firstname);
    setLastName(lastname);
    setMiddleName(middlename);
    setEmail(email);
    setPhone(phone);
    //setPassword(password);
    setAdmin(admin);
    setBettor(bettor);
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    setApiLoading(true);
    const body = {
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      EmailAddress: email,
      MobileNumber: phone,
      Password: password,
      Access: {
        MP: admin,
        BP: bettor,
      },
    };

    setLoading(true);
    if (firstName === "" || middleName === "" || lastName === "") {
      setEditError(true);
      setApiLoading(false);
      return;
    } else {
      setEditError(false);
      await axios
        .put(
          `${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}/${userId}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          /** setOpenEdit(false);*/
          setPassword("");
          console.log(res);
          setLoading(false);
          setApiLoading(false);

          setRequestStatus({
            status: true,
            error: false,
            msg: "User edited successfully.",
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setApiLoading(false);
          setPassword("");
          setRequestStatus({
            status: true,
            error: true,
            msg: "There was a problem with your request.",
          });
        });
    }
  };

  const [userIdPoints, setUserIdPoints] = useState("");
  const [usernamePoints, setUsernamePoints] = useState("");
  const [points, setPoints] = useState("");
  const [pointsError, setPointsError] = useState(false);

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoading(true);
    setApiLoading(true);
    const body = {
      DestinationAccount: userIdPoints,
      Amount: points,
    };
    if (points === "") {
      setPointsError(true);
      setApiLoading(false);
      setLoading(false);
      return;
    } else {
      setPointsError(false);
      await axios
        .post(`${ApiBaseUrl.MP + ApiBaseUrl.Financials.AddPoints}`, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          setApiLoading(false);
          setLoading(false);
          /** setPoints("");
          setOpenAdd(false); */
          setRequestStatus({
            status: true,
            error: false,
            msg: "Points successfully added.",
          });
        })
        .catch((err) => {
          /** setOpenAdd(false);
          setPoints("");*/
          console.log(err);
          setLoading(false);
          setApiLoading(false);
          setRequestStatus({
            status: true,
            error: true,
            msg: "There was a problem with your request.",
          });
        });
    }
  };

  const [openAddUser, setOpenAddUser] = useState(false);
  const handleCloseAddUser = () => {
    setOpenAddUser(false);
    setAddError({ status: false, msg: "" });
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [userUsername, setUserUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const generatePassword = () => {
    const d = new Date();
    let year = d.getFullYear();
    const password = makeid(7) + "@" + year;
    return password;
  };

  const [values, setValues] = useState({
    amount: "",
    password: generatePassword(),
    weight: "",
    weightRange: "",
    showPassword: true,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [addError, setAddError] = useState({ status: false, msg: "" });
  const handleAddUser = async (e) => {
    e.preventDefault();

    if (
      userUsername === "" ||
      userFirstname === "" ||
      userLastname === "" ||
      userPassword === ""
    ) {
      setAddError({ status: true, msg: "All fields must be filled." });
      return;
    }
    if (!checkForSpecialChar(values.password) || !hasNumber(values.password)) {
      setAddError({
        status: true,
        msg: "Password must not be empty, must contain special characters (!, @, #, $, etc.), and must contain a number",
      });
      return;
    }

    const body = {
      UserName: userUsername,
      Password: userPassword,
      //Password: values.password,
      FirstName: userFirstname,
      LastName: userLastname,
      EmailAddress: userEmail,
      MobileNumber: userPhone,
    };
    setLoading(true);

    await axios
      .post(`${ApiBaseUrl.MP + ApiBaseUrl.Users.FetchAllUsers}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        /**setUserUsername("");
        setValues({
          amount: "",
          password: "",
          weight: "",
          weightRange: "",
          showPassword: true,
        });
        setUserLastname("");
        setUserFirstname("");
        setOpenAddUser(false);*/

        setRequestStatus({
          status: true,
          error: false,
          msg: "User added successfully",
        });
        setAddError({ status: false, msg: "" });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        //  setOpenAddUser(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with request",
        });
        setAddError({ status: false, msg: "" });
      });
  };

  const [openAgent, setOpenAgent] = useState(false);
  const handleCloseAgent = () => {
    setOpenAgent(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const [commission, setCommission] = useState("");
  const [agentID, setAgentID] = useState("");
  const [agentError, setAgentError] = useState({ state: false, msg: "" });
  const handleAgent = async (e) => {
    e.preventDefault();
    const body = {
      User: parseInt(agentID),
      CommissionRate: parseFloat(commission),
    };
    setAgentError(false);
    setApiLoading(true);
    if (commission === "" || agentID === "") {
      setAgentError({ state: true, msg: "All fields must be filled." });
      setApiLoading(false);
      return;
    }
    await axios
      .post(`${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setApiLoading(false);
        setAgentError(false);
        // setOpenAgent(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Successfully tagged as agent.",
        });
      })
      .catch((err) => {
        console.log(err);
        setApiLoading(false);
        setAgentError(false);
        //setOpenAgent(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was an error with your request.",
        });
      });
  };

  ///AGENT TABLE
  const [agents, setAgents] = useState([]);
  const [agentCollection, setAgentCollection] = useState([]);
  const [agentSearch, setAgentSearch] = useState("");
  const [agentPage, setAgentPage] = useState(0);
  const [rowsPerAgentPage, setRowsPerAgentPage] = useState(5);
  const fetchAgents = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //   console.log(res.data.data);
        setAgents(res.data.data);
        setAgentCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAgents();
  }, [apiLoading]);

  useEffect(() => {
    if (!agentSearch) {
      updateAgentPage(0);
    } else {
      handleAgentSearch();
    }
  }, [agentSearch]);

  const updateAgentPage = (p) => {
    setAgentPage(p);
    const to = rowsPerAgentPage * (p + 1);
    const from = to - rowsPerAgentPage;
    setAgentCollection(agents);
  };

  const handleAgentSearch = () => {
    const filtered = cloneDeep(
      agents.filter((user) =>
        user.User.UserName.toLowerCase().includes(agentSearch.toLowerCase())
      )
    );
    console.log(filtered);
    setAgentCollection(filtered);
  };

  const handleChangeAgentPage = (event, newPage) => {
    setAgentPage(newPage);
  };

  const handleChangeRowsPerAgentPage = (event) => {
    setRowsPerAgentPage(event.target.value);
    setAgentPage(0);
  };

  //Edit Commission Rate for Agent
  const [editCommission, setEditCommission] = useState("");
  const [editCommissionID, setEditCommissionID] = useState("");
  const handleEditCommission = async () => {
    setApiLoading(true);
    await axios
      .put(
        `${ApiBaseUrl.MP + ApiBaseUrl.Agent.Agent}/${editCommissionID}`,
        { CommissionRate: editCommission },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        //setOpenCommission(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Agent commission successfully edited.",
        });
      })
      .catch((err) => {
        console.log(err);
        //setOpenCommission(false);
        setApiLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was an error with your request.",
        });
      });
  };

  //Roles

  useEffect(() => {
    fetchRoles();
  }, []);

  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userRoleID, setUserRoleID] = useState();
  const [openRoles, setOpenRoles] = useState(false);
  const handleCloseRoles = () => {
    setOpenRoles(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };
  const fetchRoles = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.System.Roles}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssignRole = async () => {
    setApiLoading(true);
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.System.Roles}/${userRole}/assign/${
          contextData.SeqID
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setOpenRoles(false);
        setApiLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenRoles(false);
        setApiLoading(false);
      });
  };

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Navigation>
      <Helmet>
        <title>Users | BMS</title>
      </Helmet>
      <div style={{ width: "100%" }}>
        {/**Add Points modal */}
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {!requestStatus.status ? (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Add bettor Points
              </Typography>
            ) : null}
            {!requestStatus.status ? (
              <Box component="form">
                <TextField
                  id="outlined-basic"
                  label="Bettor ID"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  value={userIdPoints}
                  disabled
                />
                <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  value={usernamePoints}
                  disabled
                />
                <TextField
                  id="outlined-basic"
                  label="Amount"
                  variant="outlined"
                  type="number"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => setPoints(e.target.value)}
                />
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      onClick={() => setOpenAdd(false)}
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleAdd}
                      disabled={apiLoading ? true : false}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                onPress={() => {
                  setOpenAdd(false);
                  setPoints("");
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
                msg={requestStatus.msg}
                icon="success"
              />
            ) : (
              <ModalAlert
                onPress={() => {
                  setOpenAdd(false);
                  setPoints("");
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
                msg={requestStatus.msg}
                icon="error"
              />
            )}
          </Box>
        </Modal>
        {/**Edit modal */}
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {!requestStatus.status ? (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Edit User
              </Typography>
            ) : null}

            {!requestStatus.status ? (
              <Box component="form">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="First name"
                      variant="outlined"
                      sx={{ width: "100%", margin: ".5rem 0" }}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      value={firstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Middle name"
                      variant="outlined"
                      sx={{ width: "100%", margin: ".5rem 0" }}
                      onChange={(e) => {
                        setMiddleName(e.target.value);
                      }}
                      value={middleName}
                    />
                  </Grid>
                </Grid>
                <TextField
                  id="outlined-basic"
                  label="Last name"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastName}
                />
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  type="email"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
                <TextField
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  type="text"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  type="password"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={admin}
                          onChange={(e) => setAdmin(e.target.checked)}
                          name="gilad"
                        />
                      }
                      label="Agent Portal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={bettor}
                          onChange={(e) => setBettor(e.target.checked)}
                          name="gilad"
                        />
                      }
                      label="Bettor"
                    />
                  </Grid>
                </Grid>
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setOpenEdit(false);
                        setPassword("");
                      }}
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleEditUser}
                      disabled={apiLoading ? true : false}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                onPress={() => {
                  setOpenEdit(false);
                  setPassword("");
                  handleCloseEdit();
                }}
                msg={requestStatus.msg}
                icon="success"
              />
            ) : (
              <ModalAlert
                onPress={() => {
                  setOpenEdit(false);
                  setPassword("");
                  handleCloseEdit();
                }}
                msg={requestStatus.msg}
                icon="error"
              />
            )}
          </Box>
        </Modal>
        {/**Add User modal */}
        <Modal
          open={openAddUser}
          onClose={handleCloseAddUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {!requestStatus.status ? (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add user
                </Typography>
                {addError.status ? (
                  <Typography sx={{ color: "red" }}>{addError.msg}</Typography>
                ) : null}
              </>
            ) : null}
            {!requestStatus.status ? (
              <Box component="form">
                <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => setUserUsername(e.target.value)}
                />

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="First name"
                      variant="outlined"
                      sx={{ width: "100%", margin: ".5rem 0" }}
                      onChange={(e) => setUserFirstname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Last name"
                      variant="outlined"
                      sx={{ width: "100%", margin: ".5rem 0" }}
                      onChange={(e) => setUserLastname(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <TextField
                  id="outlined-basic"
                  label="Email address"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => setUserEmail(e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label="Phone number"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => setUserPhone(e.target.value)}
                />

                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                  }}
                  value={userPassword}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    //value={values.password}
                    value={userPassword}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>

                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      onClick={() => {
                        setOpenAddUser(false);
                        setAddError({ status: false, msg: "" });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleAddUser}
                      disabled={loading ? true : false}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                msg={requestStatus.msg}
                icon="success"
                onPress={() => {
                  setUserUsername("");
                  setValues({
                    amount: "",
                    password: "",
                    weight: "",
                    weightRange: "",
                    showPassword: true,
                  });
                  setUserLastname("");
                  setUserFirstname("");
                  setOpenAddUser(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            ) : (
              <ModalAlert
                msg={requestStatus.msg}
                icon="error"
                onPress={() => {
                  setUserUsername("");
                  setValues({
                    amount: "",
                    password: "",
                    weight: "",
                    weightRange: "",
                    showPassword: true,
                  });
                  setUserLastname("");
                  setUserFirstname("");
                  setOpenAddUser(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            )}
          </Box>
        </Modal>

        {/**Tag as agent modal */}
        <Modal
          open={openAgent}
          onClose={handleCloseAgent}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {!requestStatus.status ? (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Tag as agent
              </Typography>
            ) : null}
            {!requestStatus.status ? (
              <Box component="form">
                <TextField
                  id="outlined-basic"
                  label="Commission rate"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  onChange={(e) => setCommission(e.target.value)}
                />
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      onClick={() => {
                        setOpenAgent(false);
                        setAgentError({ state: false, msg: "" });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      disabled={apiLoading ? true : false}
                      onClick={handleAgent}
                    >
                      Tag user
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                icon="success"
                msg={requestStatus.msg}
                onPress={() => {
                  setOpenAgent(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            ) : (
              <ModalAlert
                icon="error"
                msg={requestStatus.msg}
                onPress={() => {
                  setOpenAgent(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            )}
          </Box>
        </Modal>

        {/**Edit Comissionn Modal */}
        <Modal
          open={openCommission}
          onClose={handleCloseCommission}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {!requestStatus.status ? (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Commission Rate
              </Typography>
            ) : null}
            {!requestStatus.status ? (
              <Box component="form">
                <TextField
                  id="outlined-basic"
                  label="Commission rate"
                  variant="outlined"
                  sx={{ width: "100%", margin: ".5rem 0" }}
                  value={editCommission}
                  onChange={(e) => setEditCommission(e.target.value)}
                />
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      onClick={() => {
                        setOpenCommission(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      disabled={apiLoading ? true : false}
                      onClick={handleEditCommission}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                msg={requestStatus.msg}
                icon="success"
                onPress={() => {
                  setOpenCommission(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            ) : (
              <ModalAlert
                msg={requestStatus.msg}
                icon="error"
                onPress={() => {
                  setOpenCommission(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            )}
          </Box>
        </Modal>

        {/**Assign Roles Modal 

        <Modal
          open={openRoles}
          onClose={handleCloseRoles}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Assign Role
            </Typography>
            <Box component="form">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Roles</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Role"
                  onChange={(e) => setUserRole(e.target.value)}
                  value={userRole}
                >
                  {roles.length > 0
                    ? roles.map((role) => (
                        <MenuItem value={role.SeqID}>{role.RoleName}</MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
              <Grid
                sx={{ margin: ".5rem 0" }}
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => setOpenRoles(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    disabled={apiLoading ? true : false}
                    onClick={handleAssignRole}
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>*/}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="All Users" {...a11yProps(0)} />
            <Tab label="All Agents" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {/**Users Table */}
        <TabPanel value={value} index={0}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Users
            </Typography>

            <Button
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenAddUser(true);
              }}
              hidden={true}
            >
              Add User
            </Button>
          </Box>
          <TextField
            onChange={(e) => setSearch(e.target.value)}
            id="outlined-basic"
            label="Search username"
            variant="outlined"
            sx={{ width: "100%", margin: "1rem 0" }}
          />
          <Card sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full name</TableCell>

                  <TableCell>Username</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Bettor</TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 ? (
                  /**collection
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                  collection.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {user.FirstName +
                          " " +
                          (user.MiddleName === null ? "" : user.MiddleName) +
                          " " +
                          user.LastName}
                      </TableCell>

                      <TableCell>{user.UserName}</TableCell>
                      <TableCell>
                        {numberWithCommas(
                          parseFloat(user.Wallet.AvailableBalance).toFixed(2)
                        )}
                      </TableCell>
                      <TableCell>{user.MobileNumber}</TableCell>
                      <TableCell>{user.CanAccessBP.toString()}</TableCell>
                      <TableCell>{user.CanAccessMP.toString()}</TableCell>
                      <TableCell>
                        <Button
                          sx={{ margin: ".1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setOpenAdd(true);
                            setUserIdPoints(user.SeqID);
                            setUsernamePoints(user.UserName);
                          }}
                        >
                          Points
                        </Button>
                        <Button
                          sx={{ margin: ".1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setOpenEdit(true);
                            setEditError(false);
                            setEditUser(
                              user.SeqID,
                              user.FirstName,
                              user.LastName,
                              user.MiddleName,
                              user.EmailAddress,
                              user.MobileNumber,
                              user.CanAccessMP,
                              user.CanAccessBP
                            );
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          sx={{ margin: ".1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setOpenAgent(true);
                            setAgentID(user.SeqID);
                          }}
                        >
                          Upgrade Agent
                        </Button>
                        {/** <Button
                          sx={{ margin: ".1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => setOpenRoles(true)}
                        >
                          Roles
                        </Button>*/}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No users yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            {/**<TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter> */}
          </Card>
        </TabPanel>

        {/**Agents Table */}
        <TabPanel value={value} index={1}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Agents
            </Typography>
          </Box>
          <TextField
            onChange={(e) => setAgentSearch(e.target.value)}
            id="outlined-basic"
            label="Search username"
            variant="outlined"
            sx={{ width: "100%", margin: "1rem 0" }}
          />
          <Card sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Commission Rate</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.length > 0 ? (
                  /**agentCollection
                  .slice(
                    agentPage * rowsPerAgentPage,
                    agentPage * rowsPerAgentPage + rowsPerAgentPage
                  )*/
                  agentCollection.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {user.User.FirstName +
                          " " +
                          (user.User.MiddleName === null
                            ? ""
                            : user.User.MiddleName) +
                          " " +
                          user.User.LastName}
                      </TableCell>

                      <TableCell>{user.User.UserName}</TableCell>
                      <TableCell>{user.CommissionRate}</TableCell>
                      <TableCell>
                        <Button
                          sx={{ margin: ".1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setOpenCommission(true);
                            setEditCommission(user.CommissionRate);
                            setEditCommissionID(user.SeqID);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          sx={{ margin: ".1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => navigate(`/commissions/${user.SeqID}`)}
                        >
                          Commissions
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No users yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            {/** <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                count={agents.length}
                rowsPerPage={rowsPerAgentPage}
                page={agentPage}
                onPageChange={handleChangeAgentPage}
                onRowsPerPageChange={handleChangeRowsPerAgentPage}
              />
            </TableFooter>*/}
          </Card>
        </TabPanel>
      </div>
    </Navigation>
  );
};
