import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { FaCheck, FaTimes } from "react-icons/fa";
export const ModalAlert = (props) => {
  const { onPress, msg, icon } = props;
  const renderIcon = () => {
    if (icon === "success") {
      return (
        <FaCheck fontSize={50} color="#F28500" sx={{ marginBottom: ".5rem" }} />
      );
    } else if (icon === "error") {
      return (
        <FaTimes fontSize={50} color="#F28500" sx={{ marginBottom: ".5rem" }} />
      );
    } else {
      return;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {renderIcon()}
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ margin: "1rem 0", textAlign: "center" }}
      >
        {msg}
      </Typography>
      <Button
        size="large"
        variant="contained"
        onClick={onPress}
        sx={{ width: "100% !important" }}
      >
        Ok
      </Button>
    </Box>
  );
};
