import * as React from "react";
import { useContext, useEffect, useState } from "react";

import Navigation from "../../components/navigation/Navigation";
import Helmet from "react-helmet";
import { GlobalContext } from "../../context/GlobalState";
import axios from "axios";
import {
  Typography,
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  Autocomplete,
  Modal,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import * as NBAIcons from "react-nba-logos";
import { ApiBaseUrl } from "../../utils/urlbase";
import { dateTimeFormat } from "../../utils/utils";
import { makeStyles, useTheme } from "@material-ui/core";
import { HFCode, AccessControl } from "../../utils/hfcode";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
}));
export default function Dasbhoard() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const { getPermissions, contextData, contextLoading } =
    useContext(GlobalContext);
  const token = localStorage.getItem("token");

  const [games, setGames] = useState([]);
  const [collection, setCollection] = useState([]);
  console.log(games);
  useEffect(() => {
    getPermissions(token);
  }, [contextLoading]);

  const getHandicap = (game) => {
    return game.GroupSelector.filter(
      (name) => name.GroupSelectorName === "Team"
    ).map((game) =>
      game.Options.filter(
        (name, index) =>
          name.Personality.ShortName ===
          game.Options[index].Personality.ShortName
      ).map((game) =>
        game.ResultStatus === "L" ? (
          <p style={{ color: "#D32F2F", fontWeight: "bold" }}>
            {game.Handicap > 0
              ? `+${game.Handicap}` +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "
              : game.Handicap +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "}
          </p>
        ) : (
          <p style={{ color: "#2E7D32", fontWeight: "bold" }}>
            {game.Handicap > 0
              ? `+${game.Handicap}` +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "
              : game.Handicap +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "}
          </p>
        )
      )
    );
  };

  const getOU = (game) => {
    return game.GroupSelector.filter(
      (name) => name.GroupSelectorName === "OU"
    ).map((game) =>
      game.Options.filter(
        (name, index) =>
          name.Personality.ShortName ===
          game.Options[index].Personality.ShortName
      ).map((game) =>
        game.ResultStatus === "L" ? (
          <p style={{ color: "#D32F2F", fontWeight: "bold" }}>
            {game.Handicap +
              " " +
              game.Personality.ShortName +
              ` (${game.ResultStatus})` +
              " "}
          </p>
        ) : game.ResultStatus === "D" ? (
          <p style={{ color: "#666666", fontWeight: "bold" }}>
            {game.Handicap +
              " " +
              game.Personality.ShortName +
              ` (${game.ResultStatus})` +
              " "}
          </p>
        ) : (
          <p style={{ color: "#2E7D32", fontWeight: "bold" }}>
            {game.Handicap +
              " " +
              game.Personality.ShortName +
              ` (${game.ResultStatus})` +
              " "}
          </p>
        )
      )
    );
  };

  const fetchGames = async () => {
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.Game.FetchAllGames}?SneakPeekReport=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGames(
          res.data.data.filter(
            (game) =>
              Date.now() < Date.parse(game.EndAt) &&
              game.StatusIndicator === "P"
          )
        );
        setCollection(
          res.data.data.filter(
            (game) =>
              Date.now() < Date.parse(game.EndAt) &&
              game.StatusIndicator === "P"
          )
        );

        /**  setGames(res.data.data);
        setCollection(res.data.data);*/
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchGames();
  }, []);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      fetchGames();
    }, 30000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(games);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [teamReport, setTeamReport] = useState([]);
  const [ouReport, setOuReport] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  const handleCloseReport = () => setOpenReport(false);

  console.log(contextData);
  return (
    <Navigation>
      {/**View Report Modal */}
      <Modal
        open={openReport}
        onClose={handleCloseReport}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          <Box sx={{ marginBottom: ".2rem" }}>
            <Box style={{ marginBottom: "1rem" }}>
              <Typography id="modal-modal-title" variant="h4" component="h4">
                Game Report
              </Typography>
            </Box>
            <Box>
              <Box style={{ marginBottom: "1rem" }}>
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  Totals
                </Typography>
                {ouReport.map((report) => (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography id="modal-modal-title" component="p">
                      {report.AttributeName}
                    </Typography>
                    <Typography id="modal-modal-title" component="p">
                      {report.TotalBet}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h6">
                  Teams
                </Typography>
                {teamReport.map((report) => (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography id="modal-modal-title" component="p">
                      {report.AttributeName}
                    </Typography>
                    <Typography id="modal-modal-title" component="p">
                      {report.TotalBet}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Button
            size="large"
            sx={{ width: "100%", marginTop: "1rem" }}
            variant="outlined"
            onClick={() => {
              setOpenReport(false);
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      <Helmet>
        <title>Dashboard | BMS</title>
      </Helmet>

      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="h4"
        >
          Welcome back!
        </Typography>
        <Typography>
          It's nice to see you back. See the current running bets.
        </Typography>
      </Box>
      {
        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Description</TableCell>
                <TableCell>Handicap</TableCell>
                <TableCell>Over | Under</TableCell>
                {contextLoading ? null : contextData.Permissions.HFCode.some(
                    (code) => AccessControl.SneakPeek.indexOf(code) >= 0
                  ) ? (
                  <TableCell>Report</TableCell>
                ) : null}

                <TableCell>Starts At</TableCell>
                <TableCell>Ends At</TableCell>

                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.length > 0 ? (
                collection
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((game, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {game.GameDesc}
                        </Box>
                      </TableCell>
                      <TableCell>{getHandicap(game)}</TableCell>
                      <TableCell>{getOU(game)}</TableCell>
                      {contextLoading ? null : contextData.Permissions.HFCode.some(
                          (code) => AccessControl.SneakPeek.indexOf(code) >= 0
                        ) ? (
                        <TableCell>
                          {game.SneakPeekReport.Team.map((report) => (
                            <Typography
                              sx={{ fontSize: 14, whiteSpace: "nowrap" }}
                            >
                              {report.AttributeName}: {report.TotalBet}
                            </Typography>
                          ))}
                          {game.SneakPeekReport.OU.map((report) => (
                            <Typography
                              sx={{ fontSize: 14, whiteSpace: "nowrap" }}
                            >
                              {report.AttributeName}: {report.TotalBet}
                            </Typography>
                          ))}
                        </TableCell>
                      ) : null}

                      <TableCell>{dateTimeFormat(game.StartAt)}</TableCell>
                      <TableCell>{dateTimeFormat(game.EndAt)}</TableCell>

                      <TableCell>
                        {game.StatusIndicator === "D"
                          ? "Draft"
                          : game.StatusIndicator === "P"
                          ? "Published"
                          : game.StatusIndicator === "C"
                          ? "Cancelled"
                          : game.StatusIndicator === "FC"
                          ? "Closed"
                          : null}
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No games yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={games.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Card>
      }
    </Navigation>
  );
}
