export default (state, action) => {
    switch (action.type) {
      case "GET_PERMISSIONS":
        return {
          ...state,
          data: action.payload,
          loading: false
        };
      case "PERMISSIONS_ERROR":
        return {
          ...state,
         // loading: false,
          error: action.payload
        };
      case "FETCHING":
        return {
          ...state,
          loading:true
        }
      default:
        return state;
    }
  };