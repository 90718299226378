import React from "react";
import logo from "./logo.svg";
import "./App.css";

//import { Routes } from './components/routes';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from "./pages/dashboard/Dashboard";
import Sidebar from "./components/sidebar/Sidebar";
import { Users } from "./pages/users/Users";
import { Login } from "./pages/login/Login";
import { BetSlips } from "./pages/betslips/BetSlips";
import { Withdrawals } from "./pages/withdrawals/Withdrawals";
import { Roles } from "./pages/roles/Roles";
import Commissions from "./pages/commissions/Commissions";
import Navigation from "./components/navigation/Navigation";
import { FundTransfer } from "./pages/fundtransfer/FundTransfer";
import Agent from "./pages/agent/Agent";
import { Accounting } from "./pages/accounting/Accounting";
import { PrivateRoute, PublicRoute } from "./utils/router";

import { Personalities } from "./pages/personalities/Personalities";
import { Audit } from "./pages/audit/Audit";
import { Games } from "./pages/games/Games";
import { Ledger } from "./pages/ledger/Ledger";
const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outlinedLight" },
          style: {
            textTransform: "none",
            border: `1px solid #ffff`,
            color: "#ffff",
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: "#F28500",
      contrastText: "#ffffff",
      dark: "#d47604",
    },
    secondary: {
      main: "#ffea00",
    },
    warning: {
      main: "#ff1744",
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      textTransform: "none",
      letterSpacing: 0,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/games"
              element={
                <PrivateRoute>
                  <Games />
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/betslips"
              element={
                <PrivateRoute>
                  <BetSlips />
                </PrivateRoute>
              }
            />
            <Route
              path="/betslips/:slipsid"
              element={
                <PrivateRoute>
                  <BetSlips />
                </PrivateRoute>
              }
            />
            <Route
              path="/withdrawals"
              element={
                <PrivateRoute>
                  <Withdrawals />
                </PrivateRoute>
              }
            />
            <Route
              path="/agent"
              element={
                <PrivateRoute>
                  <Agent />
                </PrivateRoute>
              }
            />
            <Route
              path="/commissions/:userid"
              element={
                <PrivateRoute>
                  <Commissions />
                </PrivateRoute>
              }
            />

            <Route
              path="/roles"
              element={
                <PrivateRoute>
                  <Roles />
                </PrivateRoute>
              }
            />

            <Route
              path="/accounting/:id"
              element={
                <PrivateRoute>
                  <Accounting />
                </PrivateRoute>
              }
            />

            <Route
              path="/myledger/:id"
              element={
                <PrivateRoute>
                  <Ledger />
                </PrivateRoute>
              }
            />

            <Route
              path="/fundtransfer"
              element={
                <PrivateRoute>
                  <FundTransfer />
                </PrivateRoute>
              }
            />

            <Route
              path="/personalities"
              element={
                <PrivateRoute>
                  <Personalities />
                </PrivateRoute>
              }
            />
            <Route
              path="/audit"
              element={
                <PrivateRoute>
                  <Audit />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
