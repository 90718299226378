import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  Autocomplete,
  Modal,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import * as NBAIcons from "react-nba-logos";
import AddIcon from "@mui/icons-material/Add";
import Navigation from "../../components/navigation/Navigation";
import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { setDate } from "date-fns";
import { UNIXConverter } from "../../utils/utils";
import { dateTimeFormat } from "../../utils/utils";
import cloneDeep from "lodash/cloneDeep";
import Helmet from "react-helmet";
import { GlobalContext } from "../../context/GlobalState";
import { makeStyles, useTheme } from "@material-ui/core";
import { getHandicapIsLocked } from "../../utils/utils";
import Menu from "@mui/material/Menu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck, FaTimes } from "react-icons/fa";
import DatePicker from "@mui/lab/DatePicker";
import { ModalAlert } from "../../components/alert/ModalAlert";
import { AccessControl } from "../../utils/hfcode";
import { BiBasketball } from "react-icons/bi";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
  stack: {
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column !important",
      alignItems: "start !important",
      justifyContent: "flex-start !important",
    },
  },
  marginSm: {
    [theme.breakpoints.down("sm")]: {
      margin: ".3rem 0 !important",
    },
  },
  fullWidthSm: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      minWidth: 200,
    },
  },
}));
export const Games = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [openAdd, setOpenAdd] = useState(false);
  const [teamOne, setTeamOne] = useState("");
  const [teamTwo, setTeamTwo] = useState("");

  const [teamOneHandicap, setTeamOneHandicap] = useState("");
  const [teamTwoHandicap, setTeamTwoHandicap] = useState("");

  const [over, setOver] = useState("");
  const [under, setUnder] = useState("");

  const [endAt, setEndAt] = useState(UNIXConverter(Date.now()));
  const [startAt, setStartAt] = useState(UNIXConverter(Date.now()));

  const [teamOneError, setTeamOneError] = useState(false);
  const [teamTwoError, setTeamTwoError] = useState(false);
  const [teamOneHandicapError, setTeamOneHandicapError] = useState(false);
  const [teamTwoHandicapError, setTeamTwoHandicapError] = useState(false);
  const [overError, setOverError] = useState(false);
  const [underError, setUnderError] = useState(false);
  const [encodeError, setEncodeError] = useState(false);

  const [handicapObject, setHandicapObject] = useState([]);
  const [OUObject, setOUObject] = useState([]);

  const [handicapPayload, setHandicapPayload] = useState([]);
  const [ouPayload, setOUPayload] = useState([]);

  const [gameNumber, setGameNumber] = useState("");

  const [openEdit, setOpenEdit] = useState(false);

  const [handicapRawScore, setHandicapRawScore] = useState("");
  const [ouRawScore, setOuRawScore] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);

  const [oddTypes, setOddTypes] = useState([]);
  const [oddTypeId, setOddTypeId] = useState("");

  const [blank, setBlank] = useState(false);

  const { getPermissions, contextData, contextLoading } =
    useContext(GlobalContext);

  useEffect(() => {
    getPermissions(token);
  }, [contextLoading]);

  const fetchTeams = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.BaseData.Personalities}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTeams(
          res.data.data.filter(
            (data) => data.ShortName !== "Over" && data.ShortName !== "Under"
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchGames = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Game.FetchAllGames}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGames(res.data.data);
        setCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOddTypes = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.BaseData.OddTypes}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOddTypes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    if (teamOne === "") {
      setTeamOneError(true);
    } else {
      setTeamOneError(false);
    }
    if (teamTwo === "") {
      setTeamTwoError(true);
    } else {
      setTeamTwoError(false);
    }
    if (teamOneHandicap === "") {
      setTeamOneHandicapError(true);
    } else {
      setTeamOneHandicapError(false);
    }
    if (teamTwoHandicap === "") {
      setTeamTwoHandicapError(true);
    } else {
      setTeamTwoHandicapError(false);
    }
    if (over === "") {
      setOverError(true);
    } else {
      setOverError(false);
    }
    if (under === "") {
      setUnderError(true);
    } else {
      setUnderError(false);
    }

    if (
      teamOne === "" ||
      teamTwo === "" ||
      teamOneHandicap === "" ||
      teamTwoHandicap === "" ||
      over === "" ||
      under === "" ||
      oddTypeId === "" ||
      league === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBlank(false);
    setLoading(true);
    setBtnLoading(true);
    const body = {
      GameOddType: oddTypeId,
      GameDesc: `${teamOne.ShortName} vs ${teamTwo.ShortName}`,
      GameLeague: league.toUpperCase(),
      BetType: 1,
      StartAt: UNIXConverter(Date.parse(startAt)),
      EndAt: UNIXConverter(Date.parse(endAt)),
      Teams: [
        {
          Id: teamOne.SeqID,
          Handicap: teamOneHandicap,
        },
        {
          Id: teamTwo.SeqID,
          Handicap: teamTwoHandicap,
        },
      ],
      Over: {
        Predicted: over,
      },
      Under: {
        Predicted: under,
      },
    };

    if (validateForm()) {
      setBtnLoading(false);
      setBlank(true);
      return;
    }
    await axios
      .post(`${ApiBaseUrl.MP + ApiBaseUrl.Game.CreateGame}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRequestStatus({
          status: true,
          error: false,
          msg: "Game successfully scheduled.",
        });
        setLoading(false);
        setBtnLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setBtnLoading(false);

        setRequestStatus({
          status: true,
          error: false,
          msg: "There was a problem with your request.",
        });
      });
  };

  const handleCloseAdd = () => {
    setLoading(false);
    setOpenAdd(false);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const teamIcon = (name) => {
    const TeamIcon = NBAIcons[name];
    if (!TeamIcon) return <BiBasketball fontSize={40} />;
    return <TeamIcon size={60} />;
  };
  const style = {
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEncodeError(false);
    setOUPayload([]);
    setHandicapPayload([]);
    setRequestStatus({
      status: false,
      error: false,
      msg: "",
    });
  };

  const getHandicap = (game) => {
    return game.GroupSelector.filter(
      (name) => name.GroupSelectorName === "Team"
    ).map((game) =>
      game.Options.filter(
        (name, index) =>
          name.Personality.ShortName ===
          game.Options[index].Personality.ShortName
      ).map((game) =>
        game.ResultStatus === "L" ? (
          <p style={{ color: "#D32F2F", fontWeight: "bold" }}>
            {game.Handicap > 0
              ? `+${game.Handicap}` +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "
              : game.Handicap +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "}
          </p>
        ) : (
          <p style={{ color: "#2E7D32", fontWeight: "bold" }}>
            {game.Handicap > 0
              ? `+${game.Handicap}` +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "
              : game.Handicap +
                " " +
                game.Personality.ShortName +
                ` (${game.ResultStatus})` +
                " "}
          </p>
        )
      )
    );
  };

  const getLeague = (game) => {
    return game.GroupSelector.filter(
      (name) => name.GroupSelectorName === "Team"
    ).map((game) =>
      game.Options.filter(
        (name, index) =>
          name.Personality.ShortName ===
          game.Options[index].Personality.ShortName
      ).map((game, idx) => game.Personality.CategoryIdentifier)
    );
  };

  const getOU = (game) => {
    return game.GroupSelector.filter(
      (name) => name.GroupSelectorName === "OU"
    ).map((game) =>
      game.Options.filter(
        (name, index) =>
          name.Personality.ShortName ===
          game.Options[index].Personality.ShortName
      ).map((game) =>
        game.ResultStatus === "L" ? (
          <p style={{ color: "#D32F2F", fontWeight: "bold" }}>
            {game.Handicap +
              " " +
              game.Personality.ShortName +
              ` (${game.ResultStatus})` +
              " "}
          </p>
        ) : game.ResultStatus === "D" ? (
          <p style={{ color: "#666666", fontWeight: "bold" }}>
            {game.Handicap +
              " " +
              game.Personality.ShortName +
              ` (${game.ResultStatus})` +
              " "}
          </p>
        ) : (
          <p style={{ color: "#2E7D32", fontWeight: "bold" }}>
            {game.Handicap +
              " " +
              game.Personality.ShortName +
              ` (${game.ResultStatus})` +
              " "}
          </p>
        )
      )
    );
  };

  const getHandicapObject = (game) => {
    return game.GroupSelector.filter(
      (name) => name.GroupSelectorName === "Team"
    );
  };

  const getOUObject = (game) => {
    return game.GroupSelector.filter((name) => name.GroupSelectorName === "OU");
  };

  const handleEncode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setBtnLoading(true);

    let ouRawScoreBody = 0;
    let newOuBody = [];
    handicapPayload.map((score) => {
      ouRawScoreBody += score.RawScore;
    });

    for (let i = 0; i < ouPayload.length; i++) {
      const rawScore = {
        ...ouPayload[i],
        ["RawScore"]: ouRawScoreBody,
      };
      newOuBody.push(rawScore);
    }

    const body = handicapPayload.concat(newOuBody);

    if (OUObject.length > 0 && ouPayload.length < 2) {
      setLoading(false);
      setBtnLoading(false);
      return;
    }
    /**if (body.length < 4 || handicapRawScore === "") {
      setEncodeError(true);
      setBtnLoading(false);
      setLoading(false);
      return;
    }*/
    /**if (body.length >= 4) {
      setEncodeError(false);
      console.log(body);
      await axios
        .post(
          `${ApiBaseUrl.MP + ApiBaseUrl.Game.EncodeGame}/${gameNumber}/result`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        .then((res) => {
          setBtnLoading(false);
          setLoading(false);
          setRequestStatus({
            status: true,
            error: false,
            msg: "Results successfully encoded.",
          });
        })
        .catch((err) => {
          console.log(err.message);
          setBtnLoading(false);
          setLoading(false);
          setRequestStatus({
            status: true,
            error: true,
            msg: "There was a problem with your request.",
          });
        });
    } */
    setEncodeError(false);
    console.log(body);
    await axios
      .post(
        `${ApiBaseUrl.MP + ApiBaseUrl.Game.EncodeGame}/${gameNumber}/result`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((res) => {
        setBtnLoading(false);
        setLoading(false);
        setRequestStatus({
          status: true,
          error: false,
          msg: "Results successfully encoded.",
        });
      })
      .catch((err) => {
        console.log(err.message);
        setBtnLoading(false);
        setLoading(false);
        setRequestStatus({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    fetchGames();
    fetchOddTypes();
  }, [loading]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [collection, setCollection] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (!search) {
      updatePage(0);
    } else {
      handleSearch();
    }
  }, [search]);

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(games);
  };

  const handleSearch = () => {
    const datesAreOnSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    const filtered =
      filter === "date_created"
        ? cloneDeep(
            games.filter(
              (game) =>
                datesAreOnSameDay(new Date(search), new Date(game.CreatedAt)) //true
              //game.CreatedAt.toLowerCase().includes(search.toLowerCase())
            )
          )
        : filter === "start_date"
        ? cloneDeep(
            games.filter((game) =>
              datesAreOnSameDay(new Date(search), new Date(game.StartAt))
            )
          )
        : filter === "team_name"
        ? cloneDeep(
            games.filter((game) =>
              game.GameDesc.toLowerCase().includes(search.toLowerCase())
            )
          )
        : null;
    setCollection(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //Menu Options
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmID, setConfirmID] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("");
  const [confirmFinish, setConfirmFinish] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const handleChangeStatus = async () => {
    setLoading(true);
    await axios
      .get(
        `${
          ApiBaseUrl.MP + ApiBaseUrl.Game.EncodeGame
        }/${confirmID}/changestatus?StatusIndicator=${confirmStatus}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setConfirmFinish({
          status: true,
          error: false,
          msg: "Status Updated Successfully!",
        });
      })
      .catch((err) => {
        setLoading(false);
        setConfirmFinish({
          status: true,
          error: true,
          msg: "There was a problem with your request.",
        });
      });
  };

  const [requestStatus, setRequestStatus] = useState({
    status: false,
    error: false,
    msg: "",
  });

  const [league, setLeague] = useState("");

  const [report, setReport] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  const handleCloseReport = () => setOpenReport(false);
  const fetchReports = async (id) => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Financials.Game}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReport(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Navigation>
      <Helmet>
        <title>Games | BMS</title>
      </Helmet>
      <div style={{ width: "100%" }}>
        {/**schedule modal */}
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "auto" }}
        >
          <Box
            sx={{
              height: "auto",
              overflow: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
            }}
            className={classes.modal}
          >
            {!requestStatus.status ? (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Schedule a game
                </Typography>
                {blank ? (
                  <Typography id="modal-modal-title" variant="p" component="p">
                    Fill all the blanks.
                  </Typography>
                ) : null}
              </>
            ) : null}

            {!requestStatus.status ? (
              <Box component="form">
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={8}>
                    <Autocomplete
                      onChange={(event, value) => setTeamOne(value)}
                      id="country-select-demo"
                      options={teams}
                      autoHighlight
                      getOptionLabel={(option) => option.LongName}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { flexShrink: 0 } }}
                          {...props}
                        >
                          {teamIcon(option.ShortName)}
                          {option.LongName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={teamOneError ? true : false}
                          label="Choose Team 1"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      error={teamOneHandicapError ? true : false}
                      onChange={(e) => setTeamOneHandicap(e.target.value)}
                      id="outlined-basic"
                      label="Handicap"
                      variant="outlined"
                      type="number"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={8}>
                    <Autocomplete
                      onChange={(event, value) => setTeamTwo(value)}
                      id="country-select-demo"
                      options={teams}
                      autoHighlight
                      getOptionLabel={(option) => option.LongName}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { flexShrink: 0 } }}
                          {...props}
                        >
                          {teamIcon(option.ShortName)}
                          {option.LongName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          error={teamTwoError ? true : false}
                          {...params}
                          label="Choose Team 2"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      error={teamTwoHandicapError ? true : false}
                      onChange={(e) => setTeamTwoHandicap(e.target.value)}
                      id="outlined-basic"
                      label="Handicap"
                      variant="outlined"
                      type="number"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6} sx={{ paddingTop: "0 !important" }}>
                    <TextField
                      error={overError ? true : false}
                      onChange={(e) => setOver(e.target.value)}
                      id="outlined-basic"
                      label="Over"
                      variant="outlined"
                      sx={{ width: "100%", margin: ".5rem 0" }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "0 !important" }}>
                    <TextField
                      error={underError ? true : false}
                      onChange={(e) => setUnder(e.target.value)}
                      id="outlined-basic"
                      label="Under"
                      variant="outlined"
                      sx={{ width: "100%", margin: ".5rem 0" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
                    <TextField
                      error={underError ? true : false}
                      onChange={(e) => setLeague(e.target.value)}
                      value={league}
                      id="outlined-basic"
                      label="League (NBA, WNBA, PBA, etc.)"
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  sx={{ margin: ".8rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={12} sx={{ paddingTop: "0 !important" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Odd Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sort by"
                        sx={{ width: "100%" }}
                        onChange={(e) => setOddTypeId(e.target.value)}
                      >
                        {oddTypes.map((type) => (
                          <MenuItem value={type.SeqID}>
                            {type.LongDesc}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Stack component="form" noValidate spacing={3}>
                  <TextField
                    id="datetime-local"
                    label="Opening"
                    type="datetime-local"
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={startAt}
                    onChange={(e) => setStartAt(e.target.value)}
                  />
                  <TextField
                    id="datetime-local"
                    label="Closing"
                    type="datetime-local"
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endAt}
                    onChange={(e) => setEndAt(e.target.value)}
                  />
                </Stack>

                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      onClick={() => setOpenAdd(false)}
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={btnLoading ? true : false}
                    >
                      Schedule
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                onPress={() => {
                  setLoading(false);
                  setOpenAdd(false);
                  setBtnLoading(false);
                  setTeamOne("");
                  setTeamOneError(false);
                  setTeamTwo("");
                  setTeamTwoError(false);
                  setTeamOneHandicap("");
                  setTeamOneHandicapError(false);
                  setTeamTwoHandicap("");
                  setTeamTwoHandicapError(false);
                  setOver("");
                  setOverError(false);
                  setUnder("");
                  setLeague("");
                  setUnderError(false);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
                msg={requestStatus.msg}
                icon="success"
              />
            ) : (
              <ModalAlert
                onPress={() => {
                  setLoading(false);
                  setOpenAdd(false);
                  setBtnLoading(false);
                  setTeamOne("");
                  setTeamOneError(false);
                  setTeamTwo("");
                  setTeamTwoError(false);
                  setTeamOneHandicap("");
                  setTeamOneHandicapError(false);
                  setTeamTwoHandicap("");
                  setTeamTwoHandicapError(false);
                  setOver("");
                  setOverError(false);
                  setUnder("");
                  setUnderError(false);
                  setLeague("");
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
                msg={requestStatus.msg}
                icon="error"
              />
            )}
          </Box>
        </Modal>
        {/**encode modal */}
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {!requestStatus.status ? (
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Encode Results
              </Typography>
            ) : null}
            {!requestStatus.status ? (
              <Box component="form">
                {handicapObject.length > 0
                  ? handicapObject.map((obj) =>
                      obj.Options.map((option, index) => (
                        <Grid
                          key={index}
                          container
                          sx={{ margin: ".2rem 0" }}
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                        >
                          <Grid item xs={8}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {option.Personality.LongName}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={option.Personality.LongName}
                                onChange={(e) => {
                                  setHandicapPayload(
                                    handicapPayload.filter(
                                      (op) =>
                                        op.SelectorOptionID !== option.SeqID
                                    )
                                  );
                                  setHandicapPayload((oldArr) => [
                                    ...oldArr,
                                    JSON.parse(e.target.value),
                                  ]);
                                }}
                              >
                                <MenuItem
                                  value={`{"GroupSelectorID": ${option.GroupSelectorID}, "SelectorOptionID": ${option.SeqID}, "ResultStatus":"W", "RawScore": 0}`}
                                >
                                  Win
                                </MenuItem>
                                <MenuItem
                                  value={`{"GroupSelectorID": ${option.GroupSelectorID}, "SelectorOptionID": ${option.SeqID}, "ResultStatus":"L", "RawScore": 0}`}
                                >
                                  Loss
                                </MenuItem>
                                <MenuItem
                                  value={`{"GroupSelectorID": ${option.GroupSelectorID}, "SelectorOptionID": ${option.SeqID}, "ResultStatus":"D", "RawScore": 0}`}
                                >
                                  Draw
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              id="outlined-basic"
                              label="Raw Score"
                              variant="outlined"
                              type="number"
                              disabled={
                                handicapPayload.length > 0 ? false : true
                              }
                              onChange={(e) => {
                                setHandicapRawScore(e.target.value);
                                const findId = handicapPayload.findIndex(
                                  (op) => op.SelectorOptionID === option.SeqID
                                );
                                const rawScore = {
                                  ...handicapPayload[findId],
                                  ["RawScore"]: parseFloat(e.target.value),
                                };
                                setHandicapPayload(
                                  handicapPayload.filter(
                                    (op) => op.SelectorOptionID !== option.SeqID
                                  )
                                );
                                setHandicapPayload((oldArr) => [
                                  rawScore,
                                  ...oldArr,
                                ]);
                              }}
                            />
                          </Grid>
                        </Grid>
                      ))
                    )
                  : null}

                {OUObject.length > 0
                  ? OUObject.map((obj) =>
                      obj.Options.map((option, index) => (
                        <Grid
                          key={index}
                          container
                          sx={{ margin: ".2rem 0" }}
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                        >
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {option.Personality.LongName}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label={option.Personality.LongName}
                                onChange={(e) => {
                                  setOUPayload(
                                    ouPayload.filter(
                                      (op) =>
                                        op.SelectorOptionID !== option.SeqID
                                    )
                                  );
                                  setOUPayload((oldArr) => [
                                    ...oldArr,
                                    JSON.parse(e.target.value),
                                  ]);
                                }}
                              >
                                <MenuItem
                                  value={`{"GroupSelectorID": ${option.GroupSelectorID}, "SelectorOptionID": ${option.SeqID}, "ResultStatus":"W", "RawScore": 0}`}
                                >
                                  Win
                                </MenuItem>
                                <MenuItem
                                  value={`{"GroupSelectorID": ${option.GroupSelectorID}, "SelectorOptionID": ${option.SeqID}, "ResultStatus":"L", "RawScore": 0}`}
                                >
                                  Loss
                                </MenuItem>
                                <MenuItem
                                  value={`{"GroupSelectorID": ${option.GroupSelectorID}, "SelectorOptionID": ${option.SeqID}, "ResultStatus":"D", "RawScore": 0}`}
                                >
                                  Draw
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      ))
                    )
                  : null}

                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      onClick={() => setOpenEdit(false)}
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleEncode}
                      disabled={btnLoading ? true : false}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : !requestStatus.error ? (
              <ModalAlert
                icon="success"
                msg={requestStatus.msg}
                onPress={() => {
                  setOpenEdit(false);
                  setBtnLoading(false);
                  setHandicapPayload([]);
                  setOUPayload([]);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            ) : (
              <ModalAlert
                icon="error"
                msg={requestStatus.msg}
                onPress={() => {
                  setOpenEdit(false);
                  setBtnLoading(false);
                  setHandicapPayload([]);
                  setOUPayload([]);
                  setRequestStatus({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }}
              />
            )}
          </Box>
        </Modal>
        {/**Confirmation modal */}
        <Modal
          open={confirmModal}
          onClose={() => {
            setConfirmModal(false);
            setRequestStatus({
              status: false,
              error: false,
              msg: "",
            });
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className={classes.modal}>
            {confirmFinish.status ? (
              confirmFinish.error ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaTimes
                    fontSize={50}
                    color="#F28500"
                    sx={{ marginBottom: ".5rem" }}
                  />
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ marginBottom: "1rem" }}
                  >
                    {confirmFinish.msg}
                  </Typography>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() => {
                      setConfirmModal(false);
                      setRequestStatus({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                  >
                    Ok
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaCheck
                    fontSize={50}
                    color="#F28500"
                    sx={{ marginBottom: ".5rem" }}
                  />
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ marginBottom: "1rem" }}
                  >
                    {confirmFinish.msg}
                  </Typography>
                  <Button
                    size="large"
                    sx={{ width: "100%" }}
                    variant="contained"
                    onClick={() => setConfirmModal(false)}
                  >
                    Ok
                  </Button>
                </Box>
              )
            ) : (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Confirmation
                </Typography>
                <Typography>
                  Are you sure you want to{" "}
                  {confirmStatus === "P"
                    ? "PUBLISH"
                    : confirmStatus === "C"
                    ? "CANCEL"
                    : confirmStatus === "FC"
                    ? "CLOSE"
                    : null}{" "}
                  this game?
                </Typography>
                <Grid
                  sx={{ margin: ".5rem 0" }}
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  <Grid item xs={6}>
                    <Button
                      onClick={() => {
                        setOpenAdd(false);
                        setConfirmModal(false);
                        setRequestStatus({
                          status: false,
                          error: false,
                          msg: "",
                        });
                      }}
                      size="large"
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="large"
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={handleChangeStatus}
                      disabled={loading ? true : false}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Modal>

        {/**Reports Modal */}

        <Modal
          open={openReport}
          onClose={handleCloseReport}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflow: "auto" }}
        >
          <Box
            sx={{
              overflow: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,

              bgcolor: "background.paper",
              borderRadius: "10px",
              boxShadow: 24,
              p: 4,
            }}
            className={classes.modal}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Game Report
            </Typography>

            <Box sx={{ marginTop: 1 }}>
              <Box sx={{ marginBottom: 1 }}>
                <Typography>Total Gains: +{report.TotalGains}</Typography>
                <Typography>Total Losses: -{report.TotalLoss}</Typography>
              </Box>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Bets
              </Typography>
              <Box sx={{ maxHeight: "300px", overflow: "auto" }}>
                {report.length === 0 ? null : (
                  <>
                    {report.Ledger.map((report, idx) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={idx}
                      >
                        <Typography sx={{ width: "33.33%" }}>
                          {report.FAO.UserName}
                        </Typography>
                        <Typography sx={{ width: "33.33%" }}>
                          {report.GainOrLoss === "L" ? "Loss" : "Gain"}
                        </Typography>
                        <Typography sx={{ width: "33.33%" }}>
                          {report.Amount}
                        </Typography>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>

        <Box
          className={classes.stack}
          component="div"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h4"
          >
            Games
          </Typography>
          <Box
            className={classes.stack}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <FormControl className={classes.marginSm}>
              <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Sort by"
                sx={{ width: 150, marginRight: 1 }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setSearch("");
                }}
                className={classes.fullWidthSm}
              >
                <MenuItem value="date_created">Date Created</MenuItem>
                <MenuItem value="start_date">Start Date</MenuItem>
                <MenuItem value="team_name">Team name</MenuItem>
              </Select>
            </FormControl>
            {filter === "date_created" || filter === "start_date" ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={search}
                  onChange={(e) => setSearch(e)}
                  label="Date"
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "100%" }}
                  className={classes.marginSm}
                />
              </LocalizationProvider>
            ) : filter === "team_name" ? (
              <TextField
                placeholder="Team Name e.g. BKN, WAS, etc."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={classes.marginSm}
              />
            ) : null}

            <Button
              className={[classes.marginSm, classes.fullWidthSm]}
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenAdd(true);
              }}
              sx={{ padding: ".8rem 1rem", marginLeft: 1, flex: 4 }}
              disabled={
                contextLoading
                  ? true
                  : contextData.Permissions.HFCode.some(
                      (code) => AccessControl.GamesRoute.indexOf(code) >= 0
                    )
                  ? false
                  : true
              }
            >
              Schedule Game
            </Button>
          </Box>
        </Box>

        <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game Description</TableCell>
                <TableCell>League</TableCell>
                <TableCell>Handicap</TableCell>
                <TableCell>Over | Under</TableCell>
                <TableCell>Starts At</TableCell>
                <TableCell>Ends At</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {games.length > 0 ? (
                collection
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((game, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Box
                          component="div"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {game.GameDesc}
                        </Box>
                      </TableCell>
                      <TableCell>{game.GameLeague}</TableCell>
                      <TableCell>{getHandicap(game)}</TableCell>

                      <TableCell>{getOU(game)}</TableCell>

                      <TableCell>{dateTimeFormat(game.StartAt)}</TableCell>
                      <TableCell>{dateTimeFormat(game.EndAt)}</TableCell>
                      <TableCell>{dateTimeFormat(game.CreatedAt)}</TableCell>
                      <TableCell>
                        {game.StatusIndicator === "D"
                          ? "Draft"
                          : game.StatusIndicator === "P"
                          ? "Published"
                          : game.StatusIndicator === "C"
                          ? "Cancelled"
                          : game.StatusIndicator === "FC"
                          ? "Closed"
                          : null}
                      </TableCell>
                      <TableCell>
                        <Button
                          sx={{ margin: "0.1rem  0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setOpenEdit(true);
                            console.log(getHandicapObject(game));
                            setHandicapObject(getHandicapObject(game));
                            setOUObject(getOUObject(game));
                            setGameNumber(game.SeqID);
                          }}
                          disabled={getHandicapIsLocked(game) ? true : false}
                        >
                          Encode
                        </Button>
                        <Button
                          sx={{ margin: "0.1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setConfirmModal(true);
                            setConfirmID(game.SeqID);
                            setConfirmStatus("P");
                            setConfirmFinish({
                              status: false,
                              error: false,
                              msg: "",
                            });
                          }}
                          disabled={game.StatusIndicator === "P" ? true : false}
                        >
                          Publish
                        </Button>
                        <Button
                          sx={{ margin: "0.1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            fetchReports(game.SeqID);
                            setOpenReport(true);
                          }}
                        >
                          Reports
                        </Button>
                        <Button
                          sx={{ margin: "0.1rem 0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setConfirmModal(true);
                            setConfirmID(game.SeqID);
                            setConfirmStatus("FC");
                            setConfirmFinish({
                              status: false,
                              error: false,
                              msg: "",
                            });
                          }}
                          disabled={
                            game.StatusIndicator === "FC" ? true : false
                          }
                        >
                          Close
                        </Button>
                        <Button
                          sx={{ margin: "0.1rem  0.1rem" }}
                          variant="contained"
                          onClick={() => {
                            setConfirmModal(true);
                            setConfirmID(game.SeqID);
                            setConfirmStatus("C");
                            setConfirmFinish({
                              status: false,
                              error: false,
                              msg: "",
                            });
                          }}
                          disabled={game.StatusIndicator === "C" ? true : false}
                        >
                          Cancel
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography sx={{ textAlign: "center", width: "100%" }}>
                  No games yet.
                </Typography>
              )}
            </TableBody>
          </Table>
          <TableFooter
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={games.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Card>
      </div>
    </Navigation>
  );
};
