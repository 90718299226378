import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Card,
  TableFooter,
  TablePagination,
  Autocomplete,
  Modal,
  Grid,
  TableSortLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import * as NBAIcons from "react-nba-logos";
import AddIcon from "@mui/icons-material/Add";
import Navigation from "../../components/navigation/Navigation";

import axios from "axios";
import { ApiBaseUrl } from "../../utils/urlbase";
import { dateTimeFormat } from "../../utils/utils";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import Helmet from "react-helmet";
import { makeStyles, useTheme } from "@material-ui/core";
import DateTimePicker from "@mui/lab/DateTimePicker";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
const useStyles = makeStyles((theme) => ({
  modal: {
    [theme.breakpoints.only("xs")]: {
      width: "90% !important",
    },
  },
  stack: {
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start !important",
    },
  },
  filterMargin: {
    [theme.breakpoints.only("xs")]: {
      marginTop: ".5rem !important",
      marginBottom: ".5rem !important",
    },
  },
}));
export const BetSlips = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  let params = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  const token = localStorage.getItem("token");
  const [games, setGames] = useState([]);

  const [slipsPage, setSlipsPage] = useState(false);
  const [slips, setSlips] = useState([]);
  const [slipsError, setSlipsError] = useState(false);

  //console.log(games);

  const [openAdd, setOpenAdd] = useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const [oneSlip, setOneSlip] = useState([]);
  const [openSlip, setOpenSlip] = useState(false);
  const handleCloseSlip = () => setOpenSlip(false);

  const fetchGames = async () => {
    await axios
      .get(`${ApiBaseUrl.MP + ApiBaseUrl.Game.FetchAllGames}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGames(res.data.data);
        setCollection(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const teamIcon = (name) => {
    const TeamIcon = NBAIcons[name];
    if (!TeamIcon) return <h4>?</h4>;
    return <TeamIcon size={60} />;
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const fetchSlips = async () => {
    await axios
      .get(
        `${ApiBaseUrl.MP + ApiBaseUrl.Game.FetchAllGames}/${
          params.slipsid
        }/slip`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setSlipsError(false);
        setSlips(res.data.data);
        setCollectionSlip(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setSlipsError(true);
      });
  };

  useEffect(() => {
    if (params.slipsid) {
      setSlipsPage(true);
      fetchSlips();
    } else {
      setSlipsPage(false);
    }
  }, [!location.state ? slipsPage : location.state.slipsid]);

  useEffect(() => {
    fetchGames();
  }, []);

  //Games pagination

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [collection, setCollection] = useState([]);
  const [filter, setFilter] = useState("");
  useEffect(() => {
    if (!search) {
      updatePage(0);
    } else {
      handleSearch();
    }
  }, [search]);

  const handleSearch = () => {
    const datesAreOnSameDay = (first, second) =>
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
    const filtered =
      filter === "date_created"
        ? cloneDeep(
            games.filter(
              (game) =>
                datesAreOnSameDay(new Date(search), new Date(game.CreatedAt)) //true
              //game.CreatedAt.toLowerCase().includes(search.toLowerCase())
            )
          )
        : filter === "start_date"
        ? cloneDeep(
            games.filter((game) =>
              datesAreOnSameDay(new Date(search), new Date(game.StartAt))
            )
          )
        : filter === "team_name"
        ? cloneDeep(
            games.filter((game) =>
              game.GameDesc.toLowerCase().includes(search.toLowerCase())
            )
          )
        : null;
    setCollection(filtered);
  };

  const updatePage = (p) => {
    setPage(p);
    const to = rowsPerPage * (p + 1);
    const from = to - rowsPerPage;
    setCollection(games);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  ///Slips page pagination
  const [pageSlip, setPageSlip] = useState(0);
  const [rowsPerPageSlip, setRowsPerPageSlip] = useState(5);
  const [collectionSlip, setCollectionSlip] = useState([]);

  const updatePageSlip = (p) => {
    setPageSlip(p);
    const to = rowsPerPageSlip * (p + 1);
    const from = to - rowsPerPageSlip;
    setCollectionSlip(slips);
  };
  const handleChangePageSlip = (event, newPage) => {
    setPageSlip(newPage);
  };

  const handleChangeRowsPerPageSlip = (event) => {
    setRowsPerPageSlip(event.target.value);
    setPageSlip(0);
  };

  return (
    <Navigation>
      <Helmet>
        <title>Betslips | BMS</title>
      </Helmet>
      <Modal
        open={openSlip}
        onClose={handleCloseSlip}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classes.modal}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ margin: ".5rem 0" }}
          >
            Bet Slip
          </Typography>
          <Box component="div">
            {oneSlip.map((slip) =>
              slip.Selected.map((selected, idx) => (
                <Box
                  key={idx}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ margin: "0" }}>
                    {selected.Personality.ShortName +
                      " " +
                      selected.Handicap +
                      " " +
                      selected.ResultStatus}
                  </p>
                  <p style={{ margin: "0" }}>{slip.BetAmount}</p>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </Modal>
      {!location.state ? (
        slipsPage ? (
          <div style={{ height: 400, width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
                variant="h4"
              >
                Bet Slips
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ margin: ".5rem 0" }}
              >
                {slips.length > 0 ? slips.length + " slips" : null}
              </Typography>
            </Box>
            <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Game Desc</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Created at</TableCell>

                    <TableCell>View Bet Slips</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slips.length > 0 ? (
                    collectionSlip
                      .slice(
                        pageSlip * rowsPerPageSlip,
                        pageSlip * rowsPerPageSlip + rowsPerPageSlip
                      )
                      .map((game, idx) => (
                        <TableRow key={idx}>
                          <TableCell>
                            <Box
                              component="div"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {game.BetGame.Description}
                              {/** }  {teamIcon(
                                game.BetGame.Description.substring(0, 3)
                              )}
                              {game.BetGame.Description.substring(0, 3)}
                              &nbsp;&nbsp;
                              {" vs "}&nbsp;&nbsp;
                              {game.BetGame.Description.slice(
                                game.BetGame.Description.length - 3
                              )}
                              {teamIcon(
                                game.BetGame.Description.slice(
                                  game.BetGame.Description.length - 3
                                )
                                )}*/}
                            </Box>
                          </TableCell>
                          <TableCell>{game.CreatedBy.UserName}</TableCell>
                          <TableCell>
                            {dateTimeFormat(game.CreatedAt)}
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() => {
                                setOpenSlip(true);
                                setOneSlip(game.Particulars);
                              }}
                            >
                              View Slip
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : !slipsError ? (
                    <Typography sx={{ textAlign: "center", width: "100%" }}>
                      No bet slips yet.
                    </Typography>
                  ) : (
                    <p>No Slips Found</p>
                  )}
                </TableBody>
              </Table>
              <TableFooter
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={slips.length}
                  rowsPerPage={rowsPerPageSlip}
                  page={pageSlip}
                  onPageChange={handleChangePageSlip}
                  onRowsPerPageChange={handleChangeRowsPerPageSlip}
                />
              </TableFooter>
            </Card>
          </div>
        ) : (
          <Typography sx={{ textAlign: "center", width: "100%" }}>
            No bet slips yet.
          </Typography>
        )
      ) : location.state.slipsid ? null : (
        <div style={{ height: 400, width: "100%" }}>
          <Box
            className={classes.stack}
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h4"
            >
              Bet Slips
            </Typography>
            <Box>
              <FormControl className={classes.filterMargin}>
                <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sort by"
                  sx={{ width: 150, marginRight: 1 }}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    setSearch("");
                  }}
                >
                  <MenuItem value="date_created">Date Created</MenuItem>
                  <MenuItem value="start_date">Start Date</MenuItem>
                  <MenuItem value="team_name">Team name</MenuItem>
                </Select>
              </FormControl>
              {filter === "date_created" || filter === "start_date" ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={classes.filterMargin}
                    value={search}
                    onChange={(e) => setSearch(e)}
                    label="Date"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              ) : filter === "team_name" ? (
                <TextField
                  placeholder="Team Name e.g. BKN, WAS, etc."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              ) : null}
            </Box>
          </Box>

          <Card sx={{ marginTop: "1rem", overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Game Desc</TableCell>
                  <TableCell>Starts on</TableCell>
                  <TableCell>Ends on</TableCell>
                  <TableCell>Progress</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>View Bet Slips</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {games.length > 0 ? (
                  collection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((game, idx) => (
                      <TableRow key={idx}>
                        <TableCell>
                          <Box
                            component="div"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {game.GameDesc}
                            {/**  {teamIcon(game.GameDesc.substring(0, 3))}
                            {game.GameDesc.substring(0, 3)}&nbsp;&nbsp;
                            {" vs "}&nbsp;&nbsp;
                            {game.GameDesc.slice(game.GameDesc.length - 3)}
                            {teamIcon(
                              game.GameDesc.slice(game.GameDesc.length - 3)
                            )} */}
                          </Box>
                        </TableCell>
                        <TableCell>{dateTimeFormat(game.StartAt)}</TableCell>
                        <TableCell>{dateTimeFormat(game.EndAt)}</TableCell>
                        <TableCell>
                          {Date.now() > Date.parse(game.EndAt)
                            ? "Ended"
                            : Date.now() > Date.parse(game.StartAt) &&
                              Date.now() < Date.parse(game.EndAt)
                            ? "On going"
                            : Date.now() < Date.parse(game.StartAt)
                            ? "Waiting"
                            : null}
                        </TableCell>
                        <TableCell>{dateTimeFormat(game.CreatedAt)}</TableCell>
                        <TableCell>
                          <a
                            variant="contained"
                            href={`/betslips/${game.SeqID}`}
                            target="_blank"
                          >
                            View slips
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <Typography sx={{ textAlign: "center", width: "100%" }}>
                    No bet slips yet.
                  </Typography>
                )}
              </TableBody>
            </Table>
            <TableFooter
              sx={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={games.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </Card>
        </div>
      )}
    </Navigation>
  );
};
